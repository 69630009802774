import {Admin} from "@models/admin";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "@/store";
import * as api from "@/api/admin";
import {CredentialData} from "common/models/credential-data";

export interface AuthState {
    adminSelf: Admin | null
}

const initialState: AuthState = {
    adminSelf: null,
}

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAdmin(state: AuthState, action: PayloadAction<Admin>) {
            state.adminSelf = action.payload
        },
    }
})

export const { reducer } = slice

export const { setAdmin } = slice.actions

export const getAdminSelf = (): AppThunk<Promise<any>> =>
    async (dispatch, getState) => {
        try {
            const credential: CredentialData | null = localStorage.getCredentialData(process.env.REACT_APP_CREDENTIAL_KEY || "");
            if (!credential)
                return
            const res = await api.getAdmin({id: credential.userId})
            await dispatch(slice.actions.setAdmin(res.data.admin))
            return res.data
        } catch (err) {
            console.error(err)
        }
    }