import { PageSizeOptions } from '@/data/options'
import { QUERY_KEY } from '@/data/query-key'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import React, {useContext, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import {
    getAdmins,
    patchAdminPassword,
    PatchAdminPasswordData,
    postAdminsActivate,
    postAdminsDeactivate
} from "@/api/admin";
import EditIcon from '@mui/icons-material/Edit';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import DoneIcon from '@mui/icons-material/Done';
import {Box, Button, IconButton, Switch, TextField} from "@mui/material";
import {Admin} from "@models/admin";
import {useFormik} from "formik";
import {chainRules, requiredInputStringRule} from "common/input-rules";
import {Snack, SnackbarContext} from "@/context/SnackbarContext";
import {ConfirmModal} from "@/components/ConfirmModal";
import {useAuthStore} from "@/hooks/useAuthStore";
import {AdminEditDetailsModal} from "@/components/AdminEditDetailsModal";
import {AdminEditPasswordModal} from "@/components/AdminEditPasswordModal";

export default function AdminList() {

    const {snack, setSnack} = useContext(SnackbarContext);

    const {
        adminSelf
    } = useAuthStore()

    const columns: GridColDef<Admin>[] = [
        {
            field: '_id',
            headerName: '管理員ID',
            sortable: false,
            flex: 1,
            minWidth: 200
        },
        {
            field: 'name',
            headerName: '名稱',
            sortable: false,
            flex: 1,
            minWidth: 200
        },
        {
            field: 'email',
            headerName: '電郵',
            sortable: false,
            flex: 1,
            minWidth: 200
        },
        // {
        //     field: 'phone',
        //     headerName: '電話',
        //     sortable: false,
        //     flex: 1,
        //     minWidth: 200
        // },

        // createdAt: DbDate
        {
            field: 'superAdmin',
            headerName: '高階管理員',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    row.superAdmin ? <DoneIcon /> : <></>
                )
            }
        },
        ...(adminSelf && adminSelf.superAdmin ? [
            {
                field: 'active',
                headerName: '可用',
                sortable: false,
                flex: 1,
                minWidth: 200,
                renderCell: ({ row }) => {
                    return <Switch
                        checked={row.active}
                        onChange={(e) => handleActiveChanged(row, e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                }
            },
            {
                field: 'action',
                headerName: '行動',
                sortable: false,
                minWidth: 120,
                renderCell: ({ row }) => {
                    return (
                        <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                            <IconButton
                                color='primary' size='small'
                                onClick={() => clickEdit(row)}
                                sx={{ ml: 0.5 }}
                            >
                                <EditIcon></EditIcon>
                            </IconButton>
                            <IconButton
                                color='primary' size='small'
                                onClick={() => handleEditPassword(row)}
                                sx={{ ml: 0.5 }}
                            >
                                <LockPersonIcon></LockPersonIcon>
                            </IconButton>
                        </Box>
                    )
                }
            }
        ] : []),
    ]


    const [paginationModel, setPaginationModel] = useState<{page: number, pageSize: number}>({
        page: 0,
        pageSize: 50
    })
    const [keyword, setKeyword] = useState<string | null>(null)

    const query = {
        count: String(paginationModel.pageSize),
        page: String(paginationModel.page + 1),
        keyword,
    }

    //     _.omitBy({
    //
    // }, (value) => _.isNil(value) || _.isEmpty(value))

    const { data, refetch, isLoading } = useQuery(
        [QUERY_KEY.ADMIN_LIST, query],
        async () => {
            return (await getAdmins({
                skip: paginationModel.pageSize * paginationModel.page,
                limit: paginationModel.pageSize,
                count: true,
                ...(!!keyword && {keyword})
            })).data
        }
    )

    const adminList = useMemo(() => {
        if (!data)
            return []
        return data.admins.map(u => ({
            id: u._id, // Add id for MUI
            ...u
        }))
    }, [data])

    const clickAdd = () => handleOpenModal()

    /***
     * Row actions
     */

    const clickEdit = (row: Admin) => handleOpenModal(row)
    const handleEditPassword = (row: Admin) => {
        setOpenPasswordModal(true)
        setEditingPasswordId(row._id)
    }
    const handleActiveChanged = async (row: Admin, active: boolean) => {
        try {
            if (active)
                await postAdminsActivate({id: row._id})
            else
                await postAdminsDeactivate({id: row._id})
            setSnack(Snack.success('成功更新'))
        }
        catch (e) {
            setSnack(Snack.error('更新失敗'))
        }
        refetch()
    }

    /***
     * Details Modal
     */

    const [editingAdmin, setEditingAdmin] = useState<Admin | null>(null)
    const [openModal, setOpenModal] = useState(false)

    const handleOpenModal = (admin?: Admin) => {
        setEditingAdmin(admin || null)
        setOpenModal(true)
    }
    const handleCloseModal = (saved: boolean) => {
        if (saved)
            refetch().then().catch()
        setOpenModal(false)
        setEditingAdmin(null)
    }

    /***
     * Password Modal
     */

    const [editingPasswordId, setEditingPasswordId] = useState('')
    const [openPasswordModal, setOpenPasswordModal] = useState(false)

    return (
        <>
            {
                adminSelf && adminSelf.superAdmin && (
                    <Button
                        variant="contained"
                        onClick={clickAdd}
                    >
                        新增
                    </Button>
                )
            }

            <DataGrid
                sx={{
                    mt: 2,
                    backgroundColor: 'white'
                }}
                loading={isLoading}
                rows={adminList}
                columns={columns}

                pagination
                paginationMode='server'
                paginationModel={paginationModel}
                rowCount={data?.count || 0}
                pageSizeOptions={PageSizeOptions}
                onPaginationModelChange={setPaginationModel}

                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableRowSelectionOnClick
            />

            {/* Details Modal */}
            <AdminEditDetailsModal open={openModal}
                                   onClose={handleCloseModal}
                                   editingAdmin={editingAdmin}/>

            {/* Password Modal */}
            <AdminEditPasswordModal open={openPasswordModal}
                                    onClose={() => setOpenPasswordModal(false)}
                                    editingAdminId={editingPasswordId}/>

        </>
    )
}
