import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import { Snack, SnackbarContext } from "@/context/SnackbarContext";
import { Box, Button, Fab, IconButton, Paper, Typography } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import { HomeworkFilesModal } from "@/components/HomeworkFilesModal";
import {getHomeworks, postBatchHomeworkMessage, postHomeworkMessage} from "@/api/homework";
import { Homework } from "@models/homework";
import { CourseSelect } from "@/components/CourseSelect";
import { getStudents } from "@/api/student";
import { Student } from "@models/student";
import DoneIcon from "@mui/icons-material/Done";
import SendIcon from "@mui/icons-material/Send";
import { GridRowSelectionModel } from "@mui/x-data-grid/models/gridRowSelectionModel";
import { Link as RouterLink } from "react-router-dom";
import { PrivateFile } from "@models/file";
import {ConfirmModal} from "@/components/ConfirmModal";

export default function HomeworkList() {

    const { snack, setSnack } = useContext(SnackbarContext);

    const columns: GridColDef<Homework>[] = [
        {
            field: "_id",
            headerName: "功課ID",
            sortable: false,
            flex: 1,
            minWidth: 200,
        },
        {
            field: "courseId",
            headerName: "課程ID",
            sortable: false,
            flex: 1,
            minWidth: 100,
        },
        {
            field: "firstName",
            headerName: "學生名字",
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    <Box>
                        {studentLookup[row.studentId] ? studentLookup[row.studentId].firstName : "-"}
                    </Box>
                );
            },
        },
        {
            field: "lastName",
            headerName: "學生姓氏",
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    <Box>
                        {studentLookup[row.studentId] ? studentLookup[row.studentId].lastName : "-"}
                    </Box>
                );
            },
        },
        {
            field: "answerFiles",
            headerName: "答案檔案數",
            sortable: false,
            flex: 1,
            minWidth: 120,
            renderCell: ({ row }) => {
                return (
                    <Box>
                        {row.answerFiles.length}
                    </Box>
                );
            },
        },
        {
            field: "markedFiles",
            headerName: "已批改檔案數",
            sortable: false,
            flex: 1,
            minWidth: 120,
            renderCell: ({ row }) => {
                return (
                    <Box>
                        {row.markedFiles.length}
                    </Box>
                );
            },
        },

        // created: DbDate,
        // submitted?: DbDate,
        // marked?: DbDate,
        {
            field: "emailSent",
            headerName: "已發送電郵",
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    row.emailSent ? <DoneIcon /> : <></>
                );
            },
        },
        {
            field: "action",
            headerName: "行動",
            sortable: false,
            minWidth: 120,
            renderCell: ({ row }) => {
                return (
                    <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
                        <IconButton
                            color="primary" size="small"
                            onClick={() => clickSendNotification(row)}
                            sx={{ ml: 0.5 }}
                        >
                            <SendIcon></SendIcon>
                        </IconButton>
                        <IconButton
                            color="primary" size="small"
                            onClick={() => clickManageFiles(row)}
                            sx={{ ml: 0.5 }}
                        >
                            <FolderIcon></FolderIcon>
                        </IconButton>
                    </Box>
                );
            },
        },
    ];

    // const [paginationModel, setPaginationModel] = useState<{page: number, pageSize: number}>({
    //     page: 0,
    //     pageSize: 50
    // })
    // const [keyword, setKeyword] = useState<string | null>(null)
    //
    // const query = {
    //     count: String(paginationModel.pageSize),
    //     page: String(paginationModel.page + 1),
    //     keyword,
    // }
    //
    // const { data, refetch, isLoading } = useQuery(
    //     [QUERY_KEY.HOMEWORK_LIST, query],
    //     async () => {
    //         return (await getHomeworks({
    //             courseId
    //         })).data
    //     }
    // )
    //
    // const homeworkList = useMemo(() => {
    //     // if (!data)
    //     //     return []
    //     // return data.homeworks.map(u => ({
    //     //     id: u._id, // Add id for MUI
    //     //     ...u
    //     // }))
    //     return [
    //         {
    //             id: "123"
    //         }
    //     ]
    // }, [data])

    const [homeworkList, setHomeworkList] = useState<Homework[]>([]);
    const [studentLookup, setStudentLookup] = useState<{ [key: string]: Student }>({});
    const [fileLookup, setFileLookup] = useState<Record<string, PrivateFile>>({});

    const clickAdd = () => {
        setOpenHomeworkFilesModal(true);
        setEditingHomework(null);
    };

    /***
     * Homework files modal
     */

    const [openHomeworkFilesModal, setOpenHomeworkFilesModal] = useState(false);
    const [editingHomework, setEditingHomework] = useState<Homework | null>(null);
    const handleCloseHomeworkFilesModal = () => {
        setOpenHomeworkFilesModal(false);
        setEditingHomework(null);
    };

    /***
     * Confirm send notification Modal
     */

    const [confirmingHomeworks, setConfirmingHomeworks] = useState<Homework[]>([])
    const [openConfirmSendNotiModal, setOpenConfirmSendNotiModal] = useState(false)
    const handleCloseConfirmSendNotiModal = () => {
        setOpenConfirmSendNotiModal(false);
        setConfirmingHomeworks([]);
    }
    const handleConfirmSendNotification = async () => {
        try {
            await postBatchHomeworkMessage({
                ids: confirmingHomeworks.map(h => ({
                    courseId: h.courseId,
                    studentId: h.studentId,
                }))
            })
            // for (const homework of confirmingHomeworks) {
            //     await postHomeworkMessage({
            //         courseId: homework.courseId,
            //         studentId: homework.studentId,
            //     });
            // }
        }
        catch (e) {
            setSnack(Snack.error("發送失敗"));
            return;
        }
        setSnack(Snack.success("成功發送"));
        handleCloseConfirmSendNotiModal();
    }

    /***
     * Row actions
     */

    const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

    const clickFloatingSendNotificationButton = () => {
        setConfirmingHomeworks(homeworkList.filter(h => selectedRows.includes(h._id!.toString())));
        setOpenConfirmSendNotiModal(true);
    }

    const handleRowSelectionChange = (rowSelectionModel: GridRowSelectionModel) => {
        setSelectedRows(rowSelectionModel);
        // if (rowSelectionModel.length !== 0) {
        //
        // }
    };

    const clickSendNotification = (row: Homework) => {
        setConfirmingHomeworks([row]);
        setOpenConfirmSendNotiModal(true);
    };

    const clickManageFiles = (row: Homework) => {
        setOpenHomeworkFilesModal(true);
        setEditingHomework(row);
    };

    /***
     * Course filter
     */

    const [courseId, setCourseId] = useState<number | string>("");
    const handleCourseIdChange = (e) => {
        setCourseId(e.target.value);
    };

    const courseIdChanged = useEffect(() => {
        fetchHomeworks();
    }, [courseId]);

    const fetchHomeworks = async () => {
        if (courseId === "")
            return;

        let studentIds: string[] = [];
        try {
            const res = await getHomeworks({
                courseId: Number(courseId),
            });
            setHomeworkList(res.data.homeworks);
            setFileLookup(res.data.files);
            studentIds = [...new Set(res.data.homeworks.map(h => h.studentId))];
        } catch (e) {

        }

        try {
            const res = await getStudents({
                studentIds
            });
            const lookup = res.data.students.reduce((l, student) => {
                l[student._id] = student;
                return l;
            }, {} as { [key: string]: Student });
            setStudentLookup(lookup);
        } catch (e) {

        }
    };

    return (
        <>
            <Button variant="contained" onClick={clickAdd}>
                新增
            </Button>

            <Paper elevation={0} sx={{ mt: 2, p: 2 }}>
                <Typography
                    variant="h6"
                    component="div"
                    sx={{ mb: 2 }}
                >
                    篩選
                </Typography>
                <CourseSelect
                    name="courseId"
                    label="課程"
                    size="small"
                    fullWidth={true}
                    value={courseId}
                    onChange={handleCourseIdChange}
                    // onBlur={formik.handleBlur}
                    // error={!!formik.errors.tutorId && formik.touched.tutorId}
                    // helperText={formik.errors.tutorId}
                ></CourseSelect>
            </Paper>

            <DataGrid
                sx={{
                    mt: 2,
                    backgroundColor: "white",
                }}
                // loading={isLoading}
                rows={homeworkList}
                columns={columns}
                getRowId={(row) => (row._id || "").toString()}

                // pagination
                // paginationMode='server'
                // paginationModel={paginationModel}
                // rowCount={data?.count || 0}
                // pageSizeOptions={PageSizeOptions}
                // onPaginationModelChange={setPaginationModel}

                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableRowSelectionOnClick

                checkboxSelection
                onRowSelectionModelChange={handleRowSelectionChange}
            />

            {/* Homework Files Modal */}
            {
                <HomeworkFilesModal open={openHomeworkFilesModal}
                                    onClose={handleCloseHomeworkFilesModal}
                                    homework={editingHomework}
                                    files={fileLookup} />
            }

            {/* Confirm Send Notification Modal */}
            {
                <ConfirmModal title={`你確定要通知${confirmingHomeworks.length}位學生？`}
                              open={openConfirmSendNotiModal}
                              onClose={handleCloseConfirmSendNotiModal}
                              onSubmit={handleConfirmSendNotification}>
                </ConfirmModal>
            }

            {/* FAB for action on multiple rows */}
            {
                selectedRows.length !== 0 &&
                <Fab sx={{
                    position: "sticky",
                    bottom: 16,
                    right: 16,
                    ml: "auto",
                    display: "flex"
                }} variant="extended" size="medium" color="primary" onClick={clickFloatingSendNotificationButton}>
                    <SendIcon sx={{ mr: 1 }} />
                    {`通知${selectedRows.length}位學生`}
                </Fab>
            }

        </>
    );
}
