import {Button, ButtonProps} from "@mui/material";
import React, {FC} from "react";
import {utils as sheetUtils, writeFile} from "xlsx";

export type CsvExportButtonProps = {
    fileName: string
    header: string[]
    rows: (string | number)[][]
} & ButtonProps

export const CsvExportButton: FC<CsvExportButtonProps> = (props) => {

    const {
        fileName,
        header,
        rows,
        ...buttonProps
    } = props

    const clickExportAsCSV = () => {
        const workbook = sheetUtils.book_new();
        const worksheet = sheetUtils.aoa_to_sheet([
            header,
            ...rows
        ]);
        sheetUtils.book_append_sheet(workbook, worksheet);
        writeFile(workbook, `${fileName}.csv`, {bookType: "csv"});
    }

    return (
        <>
            <Button variant="contained"
                    component="label"
                    {...buttonProps}
                    onClick={clickExportAsCSV}>
                CSV匯出
            </Button>
        </>
    )
}
