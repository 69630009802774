import {AlertColor, ButtonProps} from "@mui/material";
import React, {createContext, FormEventHandler, ReactNode} from "react";

class Confirm {

    title: string;
    open: boolean;
    onClose?: () => void;

    confirmButtonTitle?: string;
    confirmButtonProps?: ButtonProps;

    cancelButtonTitle?: string;

    onSubmit?: FormEventHandler<HTMLFormElement> | undefined;

    constructor(data: Confirm) {
        this.title = data.title;
        this.open = data.open;

        this.onClose = data.onClose;
        this.confirmButtonTitle = data.confirmButtonTitle;
        this.confirmButtonProps = data.confirmButtonProps;
        this.cancelButtonTitle = data.cancelButtonTitle;
        this.onSubmit = data.onSubmit;
    }

    static delete(data: Omit<Confirm, "title" | "open">) {
        return new Confirm({
            title: "你確認要刪除嗎？",
            open: true,
            ...data
        })
    }

    static close() {
        return new Confirm({
            title: "",
            open: false
        })
    }

    //
    // static error(message: string) {
    //     return new Confirm({message, color: 'error', autoHideDuration: 2500, open: true})
    // }
}

export {Confirm};

type ConfirmDefaultValue = {
    confirmation: Confirm,
    setConfirmation: React.Dispatch<React.SetStateAction<Confirm>>
};

export const ConfirmContext = createContext<ConfirmDefaultValue>({
    confirmation: new Confirm({
        title: '',
        open: false
    }),
    setConfirmation: () => {}
});
