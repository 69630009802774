import { DbDate } from "@models/common";
import { OrderStatus, PaymentMethod, ReceiptRecord } from "@models/order";
import { Product } from "@models/product";

export enum MailingStatus {
    Pending,
    Mailed
}

export const mailingStatusList = [
    MailingStatus.Pending,
    MailingStatus.Mailed,
]

export const mailingStatusUI = {
    [MailingStatus.Pending]: {
        title: "待寄出"
    },
    [MailingStatus.Mailed]: {
        title: "已寄出"
    }
}

export interface ProductOrder {
    _id: string

    studentId: string

    productItems: ProductOrderItem[]

    price: number

    status: OrderStatus
    mailingStatus: MailingStatus
    paymentMethod?: PaymentMethod

    stripeTransactionId?: string
    receiptRecords?: ReceiptRecord[]

    createdBy: string
    createdAt: DbDate
    paidAt?: DbDate
    refundedAt?: DbDate
}

export interface ProductOrderItem {
    product: Pick<Product, "discounted_price" | "price" | "title" | "_id">
    quantity: number
    price: number
}

export interface CreateProductOrderItem {
    productId: number
    quantity: number
}

export type CreateProductOrderPayload = Omit<ProductOrder, "_id" | "productItems" | "status" | "created">;
export type UpdateProductOrderPayload = Partial<Pick<ProductOrder, "price" | "paymentMethod" | "paidAt" | "refundedAt">>;

export type AdminCreateProductOrderPayload = Pick<ProductOrder,
    "studentId" | "price" | "status" | "mailingStatus" | // required
    "paymentMethod" | "paidAt" | "refundedAt"> // optional
    & { productList: CreateProductOrderItem[] }

export type AdminCreateMultiProductOrderPayload = { productOrders: AdminCreateProductOrderPayload[] }

export interface ProductOrderFilter {
    studentId?: string
    status?: OrderStatus
    mailingStatus?: MailingStatus
}