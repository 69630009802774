import {Pagination} from "common/models/pagination";
import axios from "axios";
import {SuccessRes} from "@/model/success-res";
import {
    CreateTutorLeavePayload,
    CreateTutorPayload,
    GetTutorLeaveRequestResponse,
    Tutor,
    TutorLeaveRequest, TutorLeaveRequestStatus,
    UpdateTutorPayload
} from "@models/tutor";

/***
 * @GET
 * /tutors
 */

export interface GetTutorsParams {
    keyword?: string,
}

export interface GetTutorsRes {
    tutors: Tutor[],
    count?: number
}

export async function getTutors(params: GetTutorsParams & Partial<Pagination>) {
    return await axios.get<GetTutorsRes>('/tutors', {
        params,
        token: true
    })
}

/***
 * @POST
 * /tutors
 */

export type PostTutorBody = CreateTutorPayload

export interface PostTutorRes {
    tutorUser: Tutor
}

export async function postTutor(data: PostTutorBody) {
    return await axios.post<PostTutorRes>('/tutors', data, {token: true})
}

/***
 * @PATCH
 * /tutors/{userId}
 */

export interface PatchTutorPathParams {
    id: string
}

export type PatchTutorData = UpdateTutorPayload

export async function patchTutor(pathParams: PatchTutorPathParams, data: PatchTutorData) {
    return await axios.patch<SuccessRes>(`/tutors/${pathParams.id}`, data, {token: true})
}

/***
 * @PATCH
 * /tutors/{userId}/password
 */

export interface PatchTutorPasswordPathParams {
    id: string
}

export interface PatchTutorPasswordData {
    password: string
}

export async function patchTutorPassword(pathParams: PatchTutorPasswordPathParams, data: PatchTutorPasswordData) {
    return await axios.patch<SuccessRes>(`/tutors/${pathParams.id}/password`, data, {token: true})
}

/***
 * @GET
 * /tutor-leave-requests
 */

export interface GetTutorLeaveRequestsParams {

}

export interface GetTutorLeaveRequestsRes {
    tutors: TutorLeaveRequest[],
    count?: number
}

export async function getTutorLeaveRequests(params: GetTutorsParams & Partial<Pagination>) {
    return await axios.get<GetTutorLeaveRequestsRes>('/tutor-leave-requests', {
        params,
        token: true
    })
}

/***
 * @GET
 * /tutor-leave-requests/{reqId}
 */

export interface GetTutorLeaveRequestPathParams {
    id: number
}

export type GetTutorLeaveRequestRes = GetTutorLeaveRequestResponse

export async function getTutorLeaveRequest(pathParams: GetTutorLeaveRequestPathParams) {
    return await axios.get<GetTutorLeaveRequestRes>(`/tutor-leave-requests/${pathParams.id}`, {
        token: true
    })
}

/***
 * @POST
 * /tutor-leave-requests
 */

export type PostTutorLeaveRequestBody = CreateTutorLeavePayload

export interface PostTutorLeaveRequestRes {
    tutorLeaveRequestId: number
}

export async function postTutorLeaveRequest(data: PostTutorLeaveRequestBody) {
    return await axios.post<PostTutorLeaveRequestRes>('/tutor-leave-requests', data, {token: true})
}

/***
 * @PATCH
 * /tutor-leave-requests/{reqId}/status
 */

export interface PatchTutorLeaveRequestStatusPathParams {
    id: number
}

export interface PatchTutorLeaveRequestStatusData {
    status: TutorLeaveRequestStatus
}

export async function patchTutorLeaveRequestStatus(pathParams: PatchTutorLeaveRequestStatusPathParams, data: PatchTutorLeaveRequestStatusData) {
    return await axios.patch<SuccessRes>(`/tutor-leave-requests/${pathParams.id}/status`, data, {token: true})
}

/***
 * @POST
 * /tutor-leave-requests/{reqId}/substitute-tutor
 */

export interface PostTutorLeaveRequestSubstituteTutorPathParams {
    id: number
}

export interface PostTutorLeaveRequestSubstituteTutorData {
    subTutorId: string
}

export async function postTutorLeaveRequestSubstituteTutor(pathParams: PostTutorLeaveRequestSubstituteTutorPathParams, data: PostTutorLeaveRequestSubstituteTutorData) {
    return await axios.post<SuccessRes>(`/tutor-leave-requests/${pathParams.id}/substitute-tutor`, data, {token: true})
}

/***
 * @POST
 * /tutor-leave-requests/{reqId}/notification
 */

export interface PostTutorLeaveRequestNotificationPathParams {
    id: number
}

export async function postTutorLeaveRequestNotification(pathParams: PostTutorLeaveRequestNotificationPathParams) {
    return await axios.post<SuccessRes>(`/tutor-leave-requests/${pathParams.id}/notification`, {token: true})
}