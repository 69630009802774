import {Box, styled} from "@mui/material";

export const ModalBodyBox = styled(Box)(({ theme }) => ({
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    maxHeight: '80%',
    overflow: 'auto'
}));
