import axios from "axios";
import { PrivateFile } from "@models/file";

/***
 * @GET
 * /admins
 */

export interface GetFileTempUploadUrlParams {
    filename: string,
    contentType: string,
    privatePreview: boolean,
}

export interface GetFileTempUploadUrlRes {
    uploadUrl: string;
    publicUrl: string;
    privatePreviewUrl?: string;
}

export async function getFileTempUploadUrl(params: GetFileTempUploadUrlParams) {
    return await axios.get<GetFileTempUploadUrlRes>("/files/temp/upload-url", {
        params,
        token: true,
    });
}

/***
 * @GET
 * /files/private/{studentId}/upload-url/admin
 */

export interface GetPrivateFileUploadUrlPathParams {
    studentId: string,
}

export interface GetPrivateFileUploadUrlParams {
    filename: string,
    contentType: string,
}

export interface GetPrivateFileUploadUrlRes {
    uploadUrl: {
        publicUrl: string,
        uploadUrl: string
    };
    fileId: string;
}

export async function getPrivateFileUploadUrl(pathParams: GetPrivateFileUploadUrlPathParams, params: GetPrivateFileUploadUrlParams) {
    return await axios.get<GetPrivateFileUploadUrlRes>(`/files/private/${pathParams.studentId}/upload-url/admin`, {
        params,
        token: true,
    });
}

/***
 * @GET
 * /files/private/{studentId}/{fileId}/download-url/admin
 */

export interface GetPrivateFileDownloadUrlRes {
    url: string
}

export async function getPrivateFileDownloadUrl(f: PrivateFile) {
    return await axios.get<GetPrivateFileDownloadUrlRes>(`/files/private/${f.studentId}/${f._id}/download-url/admin`, {
        token: true,
    });
}


/***
 * @GET
 * /files/private/info/admin
 */

export interface GetPrivateFileInfoParams {
    fileIds: string[],
}

export interface GetPrivateFileInfoRes {
    files: Record<string, PrivateFile>,
}

export async function getPrivateFileInfo(params: GetPrivateFileInfoParams) {
    return await axios.get<GetPrivateFileInfoRes>(`/files/private/info/admin`, {
        params: { fileIds: params.fileIds.join(",") },
        token: true,
    });
}

