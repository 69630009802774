import {
    CreateProductPayload,
    UpdateProductPayload,
    Product,
    ProductCategory,
    CreateProductCategoryPayload, UpdateProductCategoryPayload
} from "@models/product";
import {Pagination} from "common/models/pagination";
import axios from "axios";
import {SuccessRes} from "@/model/success-res";

/***
 * @GET
 * /products
 */

export interface GetProductsParams {
    keyword?: string,
    productIds?: string[]
}

export interface GetProductsRes {
    products: Product[],
    count?: number
}

export async function getProducts(params: GetProductsParams & Partial<Pagination>) {
    return await axios.get<GetProductsRes>('/products', {
        params: {
            ...params,
            ...(params.productIds && {
                studentIds: params.productIds.join(',')
            })
        },
        token: true
    })
}

/***
 * @GET
 * /products/{userId}
 */

export interface GetProductPathParams {
    id: string
}

export interface GetProductRes {
    product: Product
}

export async function getProduct(pathParams: GetProductPathParams) {
    return await axios.get<GetProductRes>(`/products/${pathParams.id}`, {
        token: true
    })
}

/***
 * @POST
 * /products
 */

export type PostProductBody = CreateProductPayload

export interface PostProductRes {
    product: Product
}

export async function postProduct(data: PostProductBody) {
    return await axios.post<PostProductRes>('/products', data, {token: true})
}


/***
 * @PATCH
 * /products/{userId}
 */

export interface PatchProductPathParams {
    id: string
}

export type PatchProductData = UpdateProductPayload

export async function patchProduct(pathParams: PatchProductPathParams, data: PatchProductData) {
    return await axios.patch<SuccessRes>(`/products/${pathParams.id}`, data, {token: true})
}

/***
 * @GET
 * /products/categories
 */

export interface GetProductCategoriesRes {
    productCategories: ProductCategory[]
}

export async function getProductCategories() {
    return await axios.get<GetProductCategoriesRes>('/products/categories', {
        token: true
    })
}

/***
 * @POST
 * /products/categories
 */

export type PostProductCategoryBody = CreateProductCategoryPayload

export interface PostProductCategoryRes {
    productCategories: ProductCategory[]
}

export async function postProductCategory(data: PostProductCategoryBody) {
    return await axios.post<PostProductCategoryRes>('/products/categories', data, {token: true})
}


/***
 * @PATCH
 * /products/categories/{productCategoryId}
 */

export interface PatchProductCategoryPathParams {
    productCategoryId: string
}

export type PatchProductCategoryData = UpdateProductCategoryPayload

export async function patchProductCategory(pathParams: PatchProductCategoryPathParams, data: PatchProductCategoryData) {
    return await axios.patch<SuccessRes>(`/products/categories/${pathParams.productCategoryId}`, data, {token: true})
}
