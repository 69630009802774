import { DbDate, DbObjectId, I18nTxt, Level } from "./common";
import { PrivateFile } from "@models/file";
import { Homework } from "@models/homework";

export enum CourseType {
    Regular,
    OneOff
}

export interface Course {
    _id: number

    type: CourseType

    title: I18nTxt
    description: I18nTxt
    level: Level
    venue: I18nTxt

    tutorId: string

    videos: string[]

    thumbnailUrl: string
    leafletUrl: string

    pricePerLesson: number
    discountedPricePerLesson: number

    isChangeClassAllowed?: boolean // regular
    notePages: number // for both types. Auto fill class when regular
    hidden: boolean

    lessonCount?: number // oneoff

    createdAt: DbDate
}

export type CreateCoursePayload = Omit<Course, "_id" | "lessonCount" | "createdAt">;
export type UpdateCoursePayload = Partial<Omit<Course, "_id" | "lessonCount" | "createdAt" | "type">>;

export interface CourseFilter {
    keyword?: string
    type?: CourseType
    level?: Level
    courseIds?: string // 1,2,3
}

export interface WebCourseFilter {
    keyword?: string
    type?: CourseType
    level?: Level
}

export interface RegularClassDef {
    _id?: DbObjectId
    courseId: number
    classId: number

    tutorId: string
    venue: I18nTxt
    weekday: number // follow dayjs definition
    start: DbDate // takes hour & minutes only
    end: DbDate  // takes hour & minutes only

    quota: number
    notePages: number
}

export type CreateClassDefPayload =
    Omit<RegularClassDef, "_id" | "courseId" | "classId" | "tutorId">
    & Partial<Pick<RegularClassDef, "tutorId" | "venue">>;
export type UpdateClassDefPayload = Partial<Omit<RegularClassDef, "_id" | "courseId" | "classId">>;

// Auto gen each month
export interface RegularClassInstance extends RegularClassDef {
    month: string // YYYY/MM
    dates: string[] // YYYY-MM-DD
}

export const REGULAR_CLASS_MONTH_FORMAT = "YYYY/MM"
export const REGULAR_CLASS_DATE_FORMAT = "YYYY-MM-DD"

export interface ListClassInstancesQuery {
    classId?: number,
    fromMonth?: string,
    toMonth?: string,
}

export type ClassParam = Pick<RegularClassInstance, "courseId" | "classId" | "month">

export type UpdateClassInstancePayload = Partial<Omit<RegularClassInstance, "_id" | "courseId" | "classId" | "month">>;

// Auto gen each month
export interface RegularLesson {
    _id: DbObjectId
    courseId: number
    classId: number
    month: string
    date: string

    tutorId: string

    start: DbDate
    end: DbDate
}

export type RegularLessonParam = Pick<RegularLesson, "courseId" | "classId" | "month" | "date">
export type UpdateRegularLessonPayload = Pick<RegularLesson, "tutorId">

export interface ListRegularLessonsQuery {
    fromMonth?: string,
    toMonth?: string,
}

export interface OneOffLesson {
    _id: DbObjectId
    courseId: number
    lessonId: number

    tutorId: string

    timeslots: OneOffLessonTimeslot[]
}

export interface OneOffLessonTimeslot {
    id: number
    tutorId: string
    start: DbDate
    end: DbDate
}

export type CreateOneOffLessonPayload = Omit<OneOffLesson, "_id" | "courseId" | "lessonId">;
export type UpdateOneOffLessonPayload = Partial<Omit<OneOffLesson, "_id" | "courseId" | "lessonId" | "timeslots"> & { timeslots: Omit<OneOffLessonTimeslot, "id">[] }>;


export interface Holiday {
    _id: DbObjectId
    date: string // YYYYMMDD
}

export interface YoutubeTitle {
    _id: string
    title: string
    lastFetch: DbDate
}

export interface WebCourseRes {
    course: Course,
    videoTitles: Record<string, string>,
    regularClasses?: RegularClassInstance[],
    regularLessons?: (RegularLesson & {enrolled: number})[],
    oneOffLessons?: OneOffLesson[],
}

export type OneOffTimeslot = Omit<OneOffLesson, "timeslots"> & { timeslot: OneOffLessonTimeslot }

export type WebPortalCourse = Course & {
    regularClasses?: RegularClassInstance[],
    regularLessons?: RegularLesson[],
    oneOffTimeslots?: OneOffTimeslot[],
    homework?: Homework
}

export interface WebPortalCourseRes {
    courses: WebPortalCourse[],
    files: Record<string, PrivateFile>
}
