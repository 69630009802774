import {AlertColor} from "@mui/material";
import React, {createContext} from "react";

class Snack {
    message?: string;
    color?: AlertColor;
    autoHideDuration?: number;
    open: boolean;

    constructor(data: Snack) {
        this.message = data.message || '';
        this.color = data.color || 'info';
        this.autoHideDuration = data.autoHideDuration || 3000;
        this.open = data.open;
    }

    static success(message: string) {
        return new Snack({message, color: 'success', autoHideDuration: 2500, open: true})
    }

    static error(message: string) {
        return new Snack({message, color: 'error', autoHideDuration: 2500, open: true})
    }
}

export {Snack};

type SnackDefaultValue = {
    snack: Snack,
    setSnack: React.Dispatch<React.SetStateAction<Snack>>
};

export const SnackbarContext = createContext<SnackDefaultValue>({
    snack: new Snack({open: false}),
    setSnack: () => {}
});
