import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {SnackbarContext} from "@/context/SnackbarContext";
import {Box, Button, IconButton} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {PageSizeOptions} from "@/data/options";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import {useQuery} from "react-query";
import {QUERY_KEY} from "@/data/query-key";
import {getProducts} from "@/api/product";
import {Product} from "@models/product";
import DoneIcon from "@mui/icons-material/Done";

export default function ProductList() {

    const {snack, setSnack} = useContext(SnackbarContext);

    const columns: GridColDef<Product>[] = [
        {
            field: '_id',
            headerName: '產品ID',
            sortable: false,
            flex: 1,
            minWidth: 200
        },
        {
            field: 'title',
            headerName: '標題',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => (<Box>{ row.title.zh }</Box>)
        },
        {
            field: 'description',
            headerName: '描述',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => (<Box>{ row.description.zh }</Box>)
        },
        // price: number,
        // discounted_price: number,
        // stock: number,
        {
            field: 'hidden',
            headerName: '已隱藏',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    row.hidden ? <DoneIcon /> : <></>
                )
            }
        },
        {
            field: 'created',
            headerName: '創建日期',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    <Box>
                        { dayjs(row.created).format('DD/MM/YYYY') }
                    </Box>
                )
            }
        },
        {
            field: 'action',
            headerName: '行動',
            sortable: false,
            minWidth: 120,
            renderCell: ({ row }) => {
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                        <IconButton
                            color='primary'
                            size='small'
                            sx={{ ml: 0.5 }}
                            component={RouterLink}
                            to={`/products/edit/${row._id}`}
                        >
                            <EditIcon></EditIcon>
                        </IconButton>
                    </Box>
                )
            }
        }
    ]

    const [paginationModel, setPaginationModel] = useState<{page: number, pageSize: number}>({
        page: 0,
        pageSize: 50
    })
    const [keyword, setKeyword] = useState<string | null>(null)

    const query = {
        count: String(paginationModel.pageSize),
        page: String(paginationModel.page + 1),
        keyword,
    }

    const { data, refetch, isLoading } = useQuery(
        [QUERY_KEY.PRODUCT_LIST, query],
        async () => {
            return (await getProducts({
                skip: paginationModel.pageSize * paginationModel.page,
                limit: paginationModel.pageSize,
                count: true,
                ...(!!keyword && {keyword}),
            })).data
        }
    )

    const productList = useMemo(() => {
        if (!data)
            return []
        return data.products.map(u => ({
            id: u._id, // Add id for MUI
            ...u
        }))
    }, [data])

    // const [studentLookup, setStudentLookup] = useState<{[key: string]: Student}>({})
    // useEffect(() => {
    //     getStudents({
    //         studentIds: [...new Set(productList.map(o => o.studentId))]
    //     })
    //         .then(res => {
    //             const lookup = res.data.students.reduce((l, student) => {
    //                 l[student._id] = student
    //                 return l
    //             }, {} as {[key: string]: Student})
    //             setStudentLookup(lookup)
    //         })
    //         .catch(e => {
    //
    //         })
    // }, [productList]);

    return (
        <>
            <Button variant="contained" component={RouterLink} to="/products/add">
                新增
            </Button>

            <DataGrid
                sx={{
                    mt: 2,
                    backgroundColor: 'white'
                }}
                loading={isLoading}
                rows={productList}
                columns={columns}

                pagination
                paginationMode='server'
                paginationModel={paginationModel}
                rowCount={data?.count || 0}
                pageSizeOptions={PageSizeOptions}
                onPaginationModelChange={setPaginationModel}

                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableRowSelectionOnClick
            />
        </>
    )
}