import React, {FC, useMemo, useState} from "react";
import {MenuItem, TextField} from "@mui/material";
import {TextFieldProps, TextFieldVariants} from "@mui/material/TextField/TextField";
import {Variant} from "@mui/material/styles/createTypography";
import {getTutors} from "@/api/tutor";
import {useQuery} from "react-query";
import {QUERY_KEY} from "@/data/query-key";

export type TutorSelectProps = {
    variant?: TextFieldVariants;
} & Omit<TextFieldProps, 'variant'>

export const TutorSelect: FC<TutorSelectProps> = (props) => {

    const [keyword, setKeyword] = useState<string | null>(null)

    const query = {
        keyword,
    }

    const { data, refetch, isLoading } = useQuery(
        [QUERY_KEY.TUTOR_LIST, query],
        async () => {
            return (await getTutors({
                ...(!!keyword && {keyword})
            })).data
        }
    )

    const tutorList = useMemo(() => {
        if (!data)
            return []
        return data.tutors.map(u => ({
            id: u._id, // Add id for MUI
            ...u
        }))
    }, [data])

    return (
        <TextField
            {...props}
            select>
            {
                tutorList.map(tutor => (
                    <MenuItem key={tutor._id} value={tutor._id}>
                        {tutor.name}
                    </MenuItem>
                ))
            }
        </TextField>
    )
}