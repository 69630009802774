export enum AuthError {
    InvalidToken = "auth/invalid-token",
    TokenExpired = "auth/token-expired",
    MissingToken = "auth/missing-token",
    UserDeactivated = "auth/user-deactivated",
    NotSuperAdmin = "auth/not-super-admin",
    UserNotFound = "auth/user-not-found",
    InvalidEmail = "auth/invalid-email",
    EmailAlreadyExists = "auth/email-already-exists",
    InvalidPassword = "auth/invalid-password",
    IsSuperAdmin = "auth/is-super-admin",
    MissingTutorClaim = "auth/missing-tutor-claim",
    MissingStudentClaim = "auth/missing-student-claim",
}
