import React, {FC, useMemo, useState} from "react";
import {Checkbox, ListItemText, MenuItem, TextField} from "@mui/material";
import {TextFieldProps, TextFieldVariants} from "@mui/material/TextField/TextField";
import {Variant} from "@mui/material/styles/createTypography";
import {getProductCategories} from "@/api/product";
import {useQuery} from "react-query";
import {QUERY_KEY} from "@/data/query-key";
import {ProductCategory} from "@models/product";

export type ProductCategorySelectProps = {
    variant?: TextFieldVariants;
} & Omit<TextFieldProps, 'variant'>

export const ProductCategorySelect: FC<ProductCategorySelectProps> = (props) => {

    const { data, refetch, isLoading } = useQuery(
        [QUERY_KEY.PRODUCT_CATEGORY_LIST],
        async () => {
            return (await getProductCategories()).data
        }
    )

    const productCategoryList = useMemo<ProductCategory[]>(() => {
        if (!data)
            return []
        return data.productCategories.map(u => ({
            id: u._id, // Add id for MUI
            ...u
        }))
    }, [data])

    return (
        <TextField
            {...props}
            select
            SelectProps={{
                multiple: true,
            }}>
            {
                productCategoryList.map(productCategory => (
                    <MenuItem key={productCategory._id} value={productCategory._id}>
                        <Checkbox checked={(props.value as number[]).indexOf(productCategory._id || -1) > -1} />
                        <ListItemText primary={productCategory.title.zh} />
                    </MenuItem>
                ))
            }
        </TextField>
    )
}