import type { Action, StoreEnhancer } from '@reduxjs/toolkit';
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux"
import {configureStore} from "@reduxjs/toolkit";
import {rootReducer} from "./rootReducer";
import type {  } from 'redux-thunk/extend-redux';
import type { ThunkAction } from 'redux-thunk';

export const store = configureStore({
    reducer: rootReducer,
    devTools: true
})

export type AppThunk<T = void> = ThunkAction<T, RootState, null, Action<string>>

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
