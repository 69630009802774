import React, {FC, useEffect, useMemo, useState} from "react";
import {Autocomplete, MenuItem, TextField} from "@mui/material";
import {TextFieldProps, TextFieldVariants} from "@mui/material/TextField/TextField";
import {getStudents, GetStudentsParams} from "@/api/student";
import {useQuery} from "react-query";
import {QUERY_KEY} from "@/data/query-key";
import {Student} from "@models/student";
import {AutocompleteProps} from "@mui/material/Autocomplete/Autocomplete";
import debounce from 'debounce';
import {Pagination} from "common/models/pagination";

export type StudentSelectProps = Omit<AutocompleteProps<string | { id: string; label: string; }, false, false, true>, 'renderInput' | 'options'> & {
    textFieldProps: {
        variant?: TextFieldVariants;
    } & Omit<TextFieldProps, 'variant'>
};

export const StudentSelect: FC<StudentSelectProps> = (props) => {

    const {
        textFieldProps,
        ...autocompleteProps
    } = props

    const [keyword, setKeyword] = useState<string | null>(null)

    const query: GetStudentsParams & Partial<Pagination> = {
        ...(!!keyword && {keyword})
    }

    const { data, refetch, isLoading } = useQuery(
        [QUERY_KEY.STUDENT_LIST, query],
        async () => {
            if (!Object.values(query).length)
                return {
                    students: []
                }
            return (await getStudents(query)).data
        }
    )

    const [studentsLookup, setStudentsLookup] = useState<{[key:string]: Student}>({})
    useEffect(() => {
        setStudentsLookup({
            ...studentsLookup,
            ...(data && data.students.reduce((l, s) => ({
                ...l,
                [s._id]: s
            }), {}))
        })
    }, [data]);

    const options = useMemo(() => {
        const opts: string[] = []
        const value = autocompleteProps.value as string
        if (value)
            opts.push(value)
        if (!keyword)
            return opts
        return [
            ...opts,
            ...Object.values(studentsLookup).map((student) => student._id)
        ]
    }, [studentsLookup, autocompleteProps])

    useEffect(() => {
        query.studentIds = [autocompleteProps.value as string]
    }, [autocompleteProps.value])

    const inputChanged = debounce((newInputValue) => setKeyword(newInputValue), 500);

    return (
        <Autocomplete
            {...autocompleteProps}
            autoComplete
            includeInputInList
            filterSelectedOptions
            noOptionsText="沒有相關學生"
            onInputChange={(event, newInputValue) => {
                inputChanged(newInputValue)
            }}
            getOptionLabel={(option) => {
                const student = studentsLookup[typeof option === 'string' ? option : option.id]
                return student ? `[${student._id}] ${student.lastName} ${student.firstName}` : ''
            }}
            options={options}
            renderInput={(params) =>
                <TextField {...params} {...textFieldProps} placeholder={'輸入學生名稱以搜尋'} />
            }
        />
    )
}
