import { ObjectId } from "bson";

export interface I18nTxt {
    zh: string,
    en: string
}

export type DbObjectId = ObjectId | string
export type DbDate = Date | string | number

export enum Level {
    Preschool,
    P1,
    P2,
    P3,
    P4,
    P5,
    P6,
    F1,
    F2,
    F3,
    F4,
    F5,
    F6,
    Adults
}
