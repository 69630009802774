import {CreateBlogPayload, UpdateBlogPayload, Blog} from "@models/blog";
import {Pagination} from "common/models/pagination";
import axios from "axios";
import {SuccessRes} from "@/model/success-res";

/***
 * @GET
 * /blogs
 */

export interface GetBlogsParams {
    keyword?: string,
}

export interface GetBlogsRes {
    blogs: Blog[],
    count?: number
}

export async function getBlogs(params: GetBlogsParams & Partial<Pagination>) {
    return await axios.get<GetBlogsRes>('/blogs', {
        params,
        token: true
    })
}

/***
 * @GET
 * /blogs/{userId}
 */

export interface GetBlogPathParams {
    id: string
}

export interface GetBlogRes {
    blog: Blog
}

export async function getBlog(pathParams: GetBlogPathParams) {
    return await axios.get<GetBlogRes>(`/blogs/${pathParams.id}`, {
        token: true
    })
}

/***
 * @POST
 * /blogs
 */

export type PostBlogBody = CreateBlogPayload

export interface PostBlogsRes {
    blog: Blog
}

export async function postBlogs(data: PostBlogBody) {
    return await axios.post<PostBlogsRes>('/blogs', data, {token: true})
}


/***
 * @PATCH
 * /blogs/{userId}
 */

export interface PatchBlogPathParams {
    id: string
}

export type PatchBlogData = UpdateBlogPayload

export async function patchBlog(pathParams: PatchBlogPathParams, data: PatchBlogData) {
    return await axios.patch<SuccessRes>(`/blogs/${pathParams.id}`, data, {token: true})
}
