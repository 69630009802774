import {Pagination} from "common/models/pagination";
import axios from "axios";
import {SuccessRes} from "@/model/success-res";
import {
    AccountRecord,
    AccountRecordFilter, BatchCreateAccountRecordPayload,
    CreateAccountRecordPayload,
    UpdateAccountRecordPayload
} from "@models/account";
import {ProductOrder} from "@models/product-order";
import { Order } from "@models/order";

/***
 * @GET
 * /account-records
 */

export type GetAccountRecordsParams = AccountRecordFilter

export interface GetAccountRecordsRes {
    accountRecords: AccountRecord[],
    count?: number
}

export async function getAccountRecords(params: GetAccountRecordsParams & Partial<Pagination>) {
    return await axios.get<GetAccountRecordsRes>('/account-records', {
        params,
        token: true
    })
}

/***
 * @GET
 * /account-records/{recordId}
 */

export interface GetAccountRecordPathParams {
    id: string
}

export interface GetAccountRecordRes {
    accountRecord: AccountRecord
}

export async function getAccountRecord(pathParams: GetAccountRecordPathParams) {
    return await axios.get<GetAccountRecordRes>(`/account-records/${pathParams.id}`, {
        token: true
    })
}

/***
 * @POST
 * /account-records
 */

export type PostAccountRecordBody = CreateAccountRecordPayload

export interface PostAccountRecordsRes {
    accountRecord: AccountRecord
}

export async function postAccountRecords(data: PostAccountRecordBody) {
    return await axios.post<PostAccountRecordsRes>('/account-records', data, {token: true})
}

/***
 * @POST
 * /account-records/batch
 */

export type PostAccountRecordBatchBody = BatchCreateAccountRecordPayload

export type PostAccountRecordsBatchRes = SuccessRes

export async function postAccountRecordsBatch(data: PostAccountRecordBatchBody) {
    return await axios.post<PostAccountRecordsBatchRes>('/account-records/batch', data, {token: true})
}

/***
 * @PATCH
 * /account-records/{recordId}
 */

export interface PatchAccountRecordPathParams {
    id: string
}

export type PatchAccountRecordData = UpdateAccountRecordPayload

export async function patchAccountRecords(pathParams: PatchAccountRecordPathParams, data: PatchAccountRecordData) {
    return await axios.patch<SuccessRes>(`/account-records/${pathParams.id}`, data, {token: true})
}

/***
 * @DELETE
 * /account-records/{recordId}
 */

export interface DeleteAccountRecordPathParams {
    id: string
}

export type DeleteAccountRecordRes = SuccessRes

export async function deleteAccountRecord(pathParams: DeleteAccountRecordPathParams) {
    return await axios.delete<DeleteAccountRecordRes>(`/account-records/${pathParams.id}`, {
        token: true
    })
}

/***
 * @GET
 * /account-records/report
 */

export interface GetAccountReportParams {
    since: number,
    until: number
}

export interface GetAccountReportRes {
    accountRecords: AccountRecord[],
    orders: Order[],
    productOrders: ProductOrder[],
}

export async function getAccountReport(params: GetAccountReportParams) {
    return await axios.get<GetAccountReportRes>(`/account-records/report`, {
        params,
        token: true
    })
}
