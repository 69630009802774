import {Pagination} from "common/models/pagination";
import axios from "axios";
import {SuccessRes} from "@/model/success-res";
import {
    Course,
    CreateCoursePayload,
    UpdateCoursePayload,
    CourseType,
    CreateOneOffLessonPayload,
    OneOffLesson,
    UpdateOneOffLessonPayload,
    RegularClassDef,
    CreateClassDefPayload,
    UpdateClassDefPayload,
    RegularClassInstance, ListClassInstancesQuery
} from "@models/course";
import {Level} from "@models/common";

/***
 * @GET
 * /courses
 */

export interface GetCoursesParams {
    keyword?: string,
    type?: CourseType,
    level?: Level,
}

export interface GetCoursesRes {
    courses: Course[],
    count?: number
}

export async function getCourses(params: GetCoursesParams & Partial<Pagination>) {
    return await axios.get<GetCoursesRes>('/courses', {
        params,
        token: true
    })
}

/***
 * @POST
 * /courses
 */

export type PostCourseBody = CreateCoursePayload

export interface PostCourseRes {
    course: Course
}

export async function postCourse(data: PostCourseBody) {
    return await axios.post<PostCourseRes>('/courses', data, {token: true})
}

/***
 * @PATCH
 * /courses/{userId}
 */

export interface PatchCoursePathParams {
    id: number
}

export type PatchCourseData = UpdateCoursePayload

export async function patchCourse(pathParams: PatchCoursePathParams, data: PatchCourseData) {
    return await axios.patch<SuccessRes>(`/courses/${pathParams.id}`, data, {token: true})
}

/***
 * @GET
 * /courses/{courseId}
 */

export interface GetCoursePathParams {
    id: number
}

export interface GetCourseRes {
    course: Course
}

export async function getCourse(pathParams: GetCoursePathParams) {
    return await axios.get<GetCourseRes>(`/courses/${pathParams.id}`,{token: true})
}

/***
 * @GET
 * /courses/{courseId}/oneoff-lessons
 */

export interface GetOneOffLessonsPathParam {
    courseId: number
}

export interface GetOneOffLessonsRes {
    lessons: OneOffLesson[],
    count?: number
}

export async function getOneOffLessons(pathParams: GetOneOffLessonsPathParam, params: Partial<Pagination>) {
    return await axios.get<GetOneOffLessonsRes>(`/courses/${pathParams.courseId}/oneoff-lessons`, {
        params,
        token: true
    })
}

/***
 * @POST
 * /courses/{courseId}/oneoff-lessons
 */

export interface PostOneOffLessonPathParams {
    courseId: number
}

export type PostOneOffLessonBody = CreateOneOffLessonPayload

export interface PostOneOffLessonRes {
    lesson: OneOffLesson
}

export async function postOneOffLesson(pathParams: PostOneOffLessonPathParams, data: PostOneOffLessonBody) {
    return await axios.post<PostOneOffLessonRes>(`/courses/${pathParams.courseId}/oneoff-lessons`, data, {token: true})
}

/***
 * @PATCH
 * /courses/{courseId}/oneoff-lessons/{lessonId}
 */

export interface PatchOneOffLessonPathParams {
    courseId: number
    lessonId: number
}

export type PatchOneOffLessonBody = UpdateOneOffLessonPayload

export interface PatchOneOffLessonRes {
    lesson: OneOffLesson
}

export async function patchOneOffLesson(pathParams: PatchOneOffLessonPathParams, data: PatchOneOffLessonBody) {
    return await axios.patch<PatchOneOffLessonRes>(`/courses/${pathParams.courseId}/oneoff-lessons/${pathParams.lessonId}`, data, {token: true})
}

/***
 * @GET
 * /courses/{courseId}/class-defs
 */

export interface GetClassDefsPathParam {
    courseId: number
}

export interface GetClassDefsRes {
    classDefs: RegularClassDef[]
}

export async function getClassDefs(pathParams: GetClassDefsPathParam) {
    return await axios.get<GetClassDefsRes>(`/courses/${pathParams.courseId}/class-defs`, {
        token: true
    })
}

/***
 * @POST
 * /courses/{courseId}/class-defs
 */

export interface PostRegularClassDefPathParams {
    courseId: number
}

export type PostRegularClassDefBody = CreateClassDefPayload

export interface PostRegularClassDefRes {
    classDef: RegularClassDef
}

export async function postRegularClassDef(pathParams: PostRegularClassDefPathParams, data: PostRegularClassDefBody) {
    return await axios.post<PostRegularClassDefRes>(`/courses/${pathParams.courseId}/class-defs`, data, {token: true})
}

/***
 * @PATCH
 * /courses/{courseId}/class-defs/{classId}
 */

export interface PatchRegularClassDefPathParams {
    courseId: number
    classId: number
}

export type PatchRegularClassDefBody = UpdateClassDefPayload

export interface PatchRegularClassDefRes {
    classDef: RegularClassDef
}

export async function patchRegularClassDef(pathParams: PatchRegularClassDefPathParams, data: PatchRegularClassDefBody) {
    return await axios.patch<PatchRegularClassDefRes>(`/courses/${pathParams.courseId}/class-defs/${pathParams.classId}`, data, {token: true})
}

/***
 * @GET
 * /courses/{courseId}/classes
 */

export interface GetRegularClassesPathParams {
    courseId: number
}

export type GetRegularClassesParams = ListClassInstancesQuery

export interface GetRegularClassesRes {
    classes: RegularClassInstance[]
}

export async function getRegularClasses(pathParams: GetRegularClassesPathParams, params: GetRegularClassesParams = {}) {
    return await axios.get<GetRegularClassesRes>(`/courses/${pathParams.courseId}/classes`, {params, token: true})
}
