import { PageSizeOptions } from '@/data/options'
import { QUERY_KEY } from '@/data/query-key'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import React, {useCallback, useContext, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import EditIcon from '@mui/icons-material/Edit';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import {Box, Button, IconButton, TextField} from "@mui/material";
import {useFormik} from "formik";
import {chainRules, formatEmailRule, formatPhoneRule, requiredInputStringRule} from "common/input-rules";
import {Snack, SnackbarContext} from "@/context/SnackbarContext";
import {getStudents, patchStudentPassword, PatchStudentPasswordData, patchStudents, postStudents} from "@/api/student";
import {CreateStudentPayload, Student} from "@models/student";
import {Level} from "@models/common";
import {ConfirmModal} from "@/components/ConfirmModal";

export default function UserList() {

    const {snack, setSnack} = useContext(SnackbarContext);

    const columns: GridColDef<Student>[] = [
        {
            field: '_id',
            headerName: '學生ID',
            sortable: false,
            flex: 1,
            minWidth: 200
        },
        {
            field: 'firstName',
            headerName: '名字',
            sortable: false,
            flex: 1,
            minWidth: 200
        },
        {
            field: 'lastName',
            headerName: '姓氏',
            sortable: false,
            flex: 1,
            minWidth: 200
        },
        {
            field: 'email',
            headerName: '電郵',
            sortable: false,
            flex: 1,
            minWidth: 200
        },
        {
            field: 'phone',
            headerName: '電話',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return `${row.phone.replace('+852', '')}`
            }
        },

        // superAdmin: boolean
        // createdAt: DbDate
        // {
        //     field: 'active',
        //     headerName: '可用',
        //     sortable: false,
        //     flex: 1,
        //     minWidth: 200,
        //     renderCell: ({ row }: {row: Admin}) => {
        //         return <Switch
        //             checked={row.active}
        //             onChange={(e) => handleActiveChanged(row, e.target.checked)}
        //             inputProps={{ 'aria-label': 'controlled' }}
        //         />
        //     }
        // },
        {
            field: 'action',
            headerName: '行動',
            sortable: false,
            minWidth: 120,
            renderCell: ({ row }) => {
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                        <IconButton
                            color='primary' size='small'
                            onClick={() => clickEdit(row)}
                            sx={{ ml: 0.5 }}
                        >
                            <EditIcon></EditIcon>
                        </IconButton>
                        <IconButton
                            color='primary' size='small'
                            onClick={() => handleEditPassword(row)}
                            sx={{ ml: 0.5 }}
                        >
                            <LockPersonIcon></LockPersonIcon>
                        </IconButton>
                    </Box>
                )
            }
        }
    ]


    const [paginationModel, setPaginationModel] = useState<{page: number, pageSize: number}>({
        page: 0,
        pageSize: 50
    })
    const [keyword, setKeyword] = useState<string | null>(null)

    const query = {
        count: String(paginationModel.pageSize),
        page: String(paginationModel.page + 1),
        keyword,
    }

    //     _.omitBy({
    //
    // }, (value) => _.isNil(value) || _.isEmpty(value))

    const { data, refetch, isLoading } = useQuery(
        [QUERY_KEY.STUDENT_LIST, query],
        async () => {
            return (await getStudents({
                skip: paginationModel.pageSize * paginationModel.page,
                limit: paginationModel.pageSize,
                count: true,
                ...(!!keyword && {keyword})
            })).data
        }
    )

    const studentList = useMemo(() => {
        if (!data)
            return []
        return data.students.map(u => ({
            id: u._id, // Add id for MUI
            ...u
        }))
    }, [data])

    const clickAdd = () => handleOpenModal()

    /***
     * Row actions
     */

    const clickEdit = (row: Student) => handleOpenModal(JSON.parse(JSON.stringify(row)))
    const handleEditPassword = (row: Student) => {
        passwordFormik.resetForm()
        setOpenPasswordModal(true)
        setEditingPasswordId(row._id)
    }

    /***
     * Details Modal
     */

    const formik = useFormik({
        initialValues: {
            phone: "",
            email: "",
            password: "",
            firstName: "",
            lastName: "",
            school: "",
            level: Level.Preschool
        },
        onSubmit: async (values) => {
            try {
                if (editingStudent && editingStudent._id) {
                    await patchStudents({id: editingStudent._id}, {
                        email: values.email,
                        phone: `+852${values.phone}`,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        school: values.school,
                        level: values.level,
                    })
                }
                else {
                    await postStudents({
                        ...values,
                        phone: `+852${values.phone}`
                    })
                }
                setSnack(Snack.success('成功儲存'))
                handleCloseModal()
                await refetch()
            }
            catch (e) {
                setSnack(Snack.error('儲存失敗'))
            }
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: (values: CreateStudentPayload) => {
            let errors = {
                phone: chainRules([requiredInputStringRule, formatPhoneRule], values.phone),
                ...(editingStudent && !editingStudent._id && {
                    email: chainRules([requiredInputStringRule, formatEmailRule], values.email),
                    password: chainRules([requiredInputStringRule], values.password)
                })
            }
            Object.trimLeaves(errors, [true, {}]);
            return errors;
        }
    })
    const [editingStudent, setEditingStudent] = useState<Student | null>(null)
    const openModal = useMemo(() => {
        return editingStudent !== null
    }, [editingStudent])
    const handleOpenModal = useCallback((student?: Student) => {
        const editingStudent: Student = student || {
            createdAt: 0,
            _id: "",
            firebaseId: "",
            phone: "",
            email: "",
            firstName: "",
            lastName: "",
            school: "",
            level: Level.Preschool
        }
        if (student)
            editingStudent.phone = student.phone.replace("+852", "")
        formik.resetForm()
        formik.setValues(Object.assignExists({
            phone: "",
            email: "",
            password: "",
            firstName: "",
            lastName: "",
            school: "",
            level: Level.Preschool
        }, editingStudent))
        setEditingStudent(editingStudent)
    }, [formik])
    const handleCloseModal = () => setEditingStudent(null)

    /***
     * Password Modal
     */

    const [editingPasswordId, setEditingPasswordId] = useState('')
    const [openPasswordModal, setOpenPasswordModal] = useState(false)
    const passwordFormik = useFormik({
        initialValues: {
            password: "",
        },
        onSubmit: async (values) => {
            try {
                await patchStudentPassword({id: editingPasswordId}, values)
                setSnack(Snack.success('成功儲存'))
                setOpenPasswordModal(false)
            }
            catch (e) {
                setSnack(Snack.error('儲存失敗'))
            }
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: (values: PatchStudentPasswordData) => {
            let errors = {
                password: chainRules([requiredInputStringRule], values.password)
            }
            Object.trimLeaves(errors, [true, {}]);
            return errors;
        }
    })

    return (
        <>
            <Button
                variant="contained"
                onClick={clickAdd}
            >
                新增
            </Button>
            <DataGrid
                sx={{
                    mt: 2,
                    backgroundColor: 'white'
                }}
                loading={isLoading}
                rows={studentList}
                columns={columns}

                pagination
                paginationMode='server'
                paginationModel={paginationModel}
                rowCount={data?.count || 0}
                pageSizeOptions={PageSizeOptions}
                onPaginationModelChange={setPaginationModel}

                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableRowSelectionOnClick
            />

            {/* Details Modal */}
            <ConfirmModal title={`${editingStudent && editingStudent._id ? '修改' : '新增'}學生`}
                          open={openModal}
                          onClose={handleCloseModal}
                          confirmButtonTitle={'儲存'}
                          confirmButtonProps={{
                              disabled: !formik.dirty || formik.isSubmitting
                          }}
                          onSubmit={formik.handleSubmit}>
                <TextField sx={{mt: 2}}
                           type="email"
                           id="email"
                           label="電郵"
                           variant="outlined"
                           size="small"
                           fullWidth={true}
                           value={formik.values.email}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           error={!!formik.errors.email && formik.touched.email}
                           helperText={formik.errors.email}
                />
                { editingStudent && !editingStudent._id && (
                    <TextField sx={{mt: 2}}
                               type="password"
                               id="password"
                               label="密碼"
                               variant="outlined"
                               size="small"
                               fullWidth={true}
                               value={formik.values.password}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               error={!!formik.errors.password && formik.touched.password}
                               helperText={formik.errors.password}
                    />
                )}
                <TextField sx={{mt: 2}}
                           id="firstName"
                           label="名字"
                           variant="outlined"
                           size="small"
                           fullWidth={true}
                           value={formik.values.firstName}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           error={!!formik.errors.firstName && formik.touched.firstName}
                           helperText={formik.errors.firstName}
                />
                <TextField sx={{mt: 2}}
                           id="lastName"
                           label="姓氏"
                           variant="outlined"
                           size="small"
                           fullWidth={true}
                           value={formik.values.lastName}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           error={!!formik.errors.lastName && formik.touched.lastName}
                           helperText={formik.errors.lastName}
                />
                <TextField sx={{mt: 2}}
                           id="school"
                           label="學校"
                           variant="outlined"
                           size="small"
                           fullWidth={true}
                           value={formik.values.school}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           error={!!formik.errors.school && formik.touched.school}
                           helperText={formik.errors.school}
                />
                <TextField sx={{mt: 2}}
                           inputProps={{ maxLength: 8 }}
                           id="phone"
                           label="電話"
                           variant="outlined"
                           size="small"
                           fullWidth={true}
                           value={formik.values.phone}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           error={!!formik.errors.phone && formik.touched.phone}
                           helperText={formik.errors.phone}
                />
                {/*level: Level*/}
            </ConfirmModal>

            {/* Password Modal */}
            <ConfirmModal title={'修改密碼'}
                          open={openPasswordModal}
                          onClose={() => setOpenPasswordModal(false)}
                          confirmButtonTitle={'儲存'}
                          confirmButtonProps={{
                              disabled: !passwordFormik.dirty || passwordFormik.isSubmitting
                          }}
                          onSubmit={passwordFormik.handleSubmit}>
                <TextField sx={{mt: 2}}
                           type="password"
                           name="password"
                           label="密碼"
                           variant="outlined"
                           size="small"
                           fullWidth={true}
                           value={passwordFormik.values.password}
                           onChange={passwordFormik.handleChange}
                           onBlur={passwordFormik.handleBlur}
                           error={!!passwordFormik.errors.password && passwordFormik.touched.password}
                           helperText={passwordFormik.errors.password}
                />
            </ConfirmModal>
        </>
    )
}
