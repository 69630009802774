import {Box, IconButton, Paper, Typography} from "@mui/material";
import React, {useState} from "react";
import {useAuthStore} from "@/hooks/useAuthStore";
import EditIcon from "@mui/icons-material/Edit";
import {AdminEditDetailsModal} from "@/components/AdminEditDetailsModal";
import {Admin} from "@models/admin";
import {useAppDispatch} from "@/store";
import {getAdminSelf} from "@/store/slices/auth";
import {AdminEditPasswordModal} from "@/components/AdminEditPasswordModal";

export default function Home() {

    const dispatch = useAppDispatch()

    const {
        adminSelf
    } = useAuthStore()

    /***
     * Details Modal
     */

    const [openModal, setOpenModal] = useState(false)

    const handleEditInfo = () => {
        setOpenModal(true)
    }
    const handleCloseModal = (saved: boolean) => {
        if (saved)
            dispatch(getAdminSelf())
        setOpenModal(false)
    }

    /***
     * Password Modal
     */

    const [openPasswordModal, setOpenPasswordModal] = useState(false)

    const handleEditPassword = () => {
        setOpenPasswordModal(true)
    }
    const handleClosePasswordModal = (saved: boolean) => {
        setOpenPasswordModal(false)
    }

    return (
        <div>
            <Typography
                variant='h6'
                component='div'
            >
                歡迎，{ adminSelf?.name }
            </Typography>

            <Paper sx={{p: 4, mt: 2, maxWidth: 300}}>
                <Box display={'flex'} justifyContent={'end'}>
                    <IconButton
                        color='primary' size='small'
                        onClick={handleEditInfo}
                        sx={{ ml: 0.5 }}
                    >
                        <EditIcon></EditIcon>
                    </IconButton>
                </Box>
                <Typography variant="subtitle2">
                    名稱
                </Typography>
                <Typography variant="body1">
                    { adminSelf?.name }
                </Typography>

                <Typography sx={{mt: 2}} variant="subtitle2">
                    電郵
                </Typography>
                <Typography variant="body1">
                    { adminSelf?.email }
                </Typography>
            </Paper>

            <Paper sx={{p: 4, mt: 2, maxWidth: 300}}>
                <Box display={'flex'} justifyContent={'end'}>
                    <IconButton
                        color='primary' size='small'
                        onClick={handleEditPassword}
                        sx={{ ml: 0.5 }}
                    >
                        <EditIcon></EditIcon>
                    </IconButton>
                </Box>
                <Typography variant="subtitle2">
                    密碼
                </Typography>
                <Typography variant="body1">
                    { adminSelf?.name }
                </Typography>
            </Paper>

            {/* Details Modal */}
            <AdminEditDetailsModal open={openModal}
                                   onClose={handleCloseModal}
                                   editingAdmin={adminSelf}/>

            {/* Password Modal */}
            {
                adminSelf && (
                    <AdminEditPasswordModal open={openPasswordModal}
                                            onClose={handleClosePasswordModal}
                                            editingAdminId={adminSelf._id}/>
                )
            }
        </div>
    )
}