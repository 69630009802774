import { Homework, HomeworkEdit } from "@models/homework";
import axios from "axios";
import { SuccessRes } from "@/model/success-res";
import { PrivateFile } from "@models/file";

/***
 * @GET
 * /homeworks
 */

export interface GetHomeworksPathParams {
    courseId: number
}

export interface GetHomeworksRes {
    homeworks: Homework[]
    files: Record<string, PrivateFile>
}

export async function getHomeworks(pathParams: GetHomeworksPathParams) {
    return await axios.get<GetHomeworksRes>(`/courses/${pathParams.courseId}/homeworks/admin`, {
        token: true,
    });
}

/***
 * @PATCH
 * /courses/{courseId}/students/{studentId}/homeworks
 */

export interface PatchHomeworkPathParams {
    courseId: number,
    studentId: string
}

export type PatchHomeworkBody = HomeworkEdit

export interface PatchHomeworkRes {
    homework: Homework
}

export async function patchHomework(pathParams: PatchHomeworkPathParams, data: PatchHomeworkBody) {
    return await axios.patch<PatchHomeworkRes>(`/courses/${pathParams.courseId}/students/${pathParams.studentId}/homeworks`, data, {
        token: true,
    });
}

/***
 * @POST
 * /courses/{courseId}/students/{studentId}/homeworks/message
 */

export interface PostHomeworkMessagePathParams {
    courseId: number,
    studentId: string
}

export async function postHomeworkMessage(pathParams: PostHomeworkMessagePathParams) {
    return await axios.post<SuccessRes>(`/courses/${pathParams.courseId}/students/${pathParams.studentId}/homeworks/message`, undefined, {
        token: true,
    });
}

/***
 * @POST
 * /homeworks/message/batch
 */

export interface PostBatchHomeworkMessageBody {
    ids: {
        courseId: number,
        studentId: string
    }[]
}

export async function postBatchHomeworkMessage(data: PostBatchHomeworkMessageBody) {
    return await axios.post<SuccessRes>(`/homeworks/message/batch`, data, {
        token: true,
    });
}
