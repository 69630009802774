import {Button, ButtonProps} from "@mui/material";
import React, {FC, useRef} from "react";
import {parseCsv} from "@/helpers/parseCsv";

export type CsvImportButtonProps<T> = {
    transformer: (row: string[]) => T
    resultChanged: (result: T[]) => void
} & ButtonProps

export const CsvImportButton = <T,>(props: CsvImportButtonProps<T>) => {

    const {
        transformer,
        resultChanged,
        ...buttonProps
    } = props

    const fileInputRef= useRef()

    const handleCSVFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        // setPreviewData({
        //     headers,
        //     previewValues: firstRow,
        //     // contacts: [
        //     //     {
        //     //         firstName: previewRow[0],
        //     //         lastName: previewRow[1],
        //     //         email: previewRow[2],
        //     //         phone: previewRow[3],
        //     //         tags: previewRow[4] || ''
        //     //     }
        //     // ]
        // })

        if (e.target.files) {
            const files = Array.from(e.target.files)
            const file = files[0]

            const reader = new FileReader()
            reader.onload = (e) => {
                const result = parseCsv(reader.result as string)
                const headers = result.shift()
                if (!headers) {
                    return
                }
                resultChanged(result.map(transformer))
            }
            reader.readAsText(file)
        }
    }

    return (
        <>
            <Button variant="contained"
                    component="label"
                    {...buttonProps}>
                CSV匯入
                <input type="file"
                       accept=".csv"
                       onChange={ handleCSVFileChange }
                       hidden/>
            </Button>
        </>
    )
}