import {
    Box,
    Button,
    IconButton, MenuItem, TextField,
    Typography,
} from "@mui/material";
import React, {FC, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useFormik} from "formik";
import {chainRules, requiredInputStringRule} from "common/input-rules";
import {OneOffLesson, RegularClassDef} from "@models/course";
import {TutorSelect} from "@/components/TutorSelect";
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';
import dayjs from "dayjs";
import {Snack, SnackbarContext} from "@/context/SnackbarContext";
import {
    getClassDefs,
    patchRegularClassDef,
    postRegularClassDef,
    PostRegularClassDefBody
} from "@/api/course";
import {useQuery} from "react-query";
import {QUERY_KEY} from "@/data/query-key";
import {ConfirmModal} from "@/components/ConfirmModal";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { useAppDispatch } from "@/store";
import {getTutorLookup} from "@/store/slices/tutor";
import { useTutorStore } from "@/hooks/useTutorStore";
import {OneOffOrderTimeslot, OrderStatus, RegularOrderTimeslot} from "@models/order";

export type CourseClassListProps = {
    courseId: number
}

export const CourseClassList: FC<CourseClassListProps> = (props) => {

    const {
        courseId
    } = props

    const {snack, setSnack} = useContext(SnackbarContext)

    const columns: GridColDef[] = [
        {
            field: 'tutorId',
            headerName: '導師ID',
            sortable: false,
            flex: 1,
            minWidth: 200
        },
        {
            field: 'timeslots',
            headerName: '日期選項',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }: { row: RegularClassDef }) => {
                return (
                    <Box sx={{ width: '100%' }}>
                        { `${dayjs().weekday(row.weekday).toDate().toLocaleDateString('zh-HK', { weekday: 'long' })}` }
                        { `${dayjs(row.start).format('HH:mm')} - ${dayjs(row.end).format('HH:mm')}` }
                        {/*{ row.timeslots && row.timeslots.map(t => (*/}
                        {/*    <Box key={t.id}>*/}
                        {/*        <Box>*/}
                        {/*            { tutorLookup[t.tutorId] ? tutorLookup[t.tutorId].name : '-' }*/}
                        {/*        </Box>*/}
                        {/*        <Box>*/}
                        {/*            { `${dayjs(t.start).format('DD/MM/YYYY HH:mm')} - ${dayjs(t.end).format('DD/MM/YYYY HH:mm')}` }*/}
                        {/*        </Box>*/}
                        {/*    </Box>*/}
                        {/*)) }*/}
                    </Box>
                )
            }
        },
        {
            field: 'action',
            headerName: '行動',
            sortable: false,
            minWidth: 120,
            renderCell: ({ row }) => {
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                        <IconButton
                            color='primary' size='small'
                            onClick={() => clickEdit(row)}
                            sx={{ ml: 0.5 }}
                        >
                            <EditIcon></EditIcon>
                        </IconButton>
                    </Box>
                )
            }
        }
    ]

    const { data, refetch, isLoading } = useQuery(
        [QUERY_KEY.CLASS_LIST, {courseId}],
        async () => {
            return (await getClassDefs({
                courseId
            })).data
        }
    )

    const lessonList = useMemo(() => {
        if (!data)
            return []
        return data.classDefs.map(l => ({
            id: l._id, // Add id for MUI
            ...l
        }))
    }, [data])

    /**
     * Init
     * */

    const dispatch = useAppDispatch()

    const {
        tutorLookup
    } = useTutorStore()

    useEffect(() => {
        dispatch(getTutorLookup())
    }, [])

    /***
     * Row actions
     */

    const clickEdit = (row: RegularClassDef) => {
        handleOpenModal(row)
    }

    /***
     * Details Modal
     */

    const [initialValues, setInitialValues] = useState({
        tutorId: "",
        venue: {
            zh: "",
            en: "",
        },
        weekday: 0,
        start: dayjs().startOf("hour").valueOf(),
        end: dayjs().startOf("hour").add(1, "hour").valueOf(),

        quota: 0,
        notePages: 0,
    });

    const formik = useFormik<PostRegularClassDefBody>({
        enableReinitialize: true,
        initialValues,
        onSubmit: async (values) => {
            try {
                if (editingRegularClassDef) {
                    await patchRegularClassDef({
                        courseId,
                        classId: editingRegularClassDef.classId
                    }, values)
                }
                else {
                    await postRegularClassDef({
                        courseId
                    }, values)
                }
                setSnack(Snack.success('成功儲存'))
                handleCloseModal()
                await refetch()
            }
            catch (e) {
                setSnack(Snack.error('儲存失敗'))
            }
        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: (values: PostRegularClassDefBody) => {
            let errors = {
                // tutorId: chainRules([requiredInputStringRule], values.tutorId),
                venue: {
                    zh: chainRules([requiredInputStringRule], values.venue.zh),
                    en: chainRules([requiredInputStringRule], values.venue.en),
                },
            }
            Object.trimLeaves(errors, [true, {}]);
            return errors;
        }
    })
    const [editingRegularClassDef, setEditingRegularClassDef] = useState<RegularClassDef | null>(null)
    const [openModal, setOpenModal] = useState(false)
    const handleOpenModal = useCallback((regularClassDef?: RegularClassDef) => {

        if (regularClassDef) {
            const optionalValues = {
            }
            const dummy = {
                ...initialValues,
                ...optionalValues
            }
            const init = Object.assignExists(dummy, regularClassDef)
            Object.trimLeaves(init, [null])
            setInitialValues(init)
        }

        formik.resetForm()
        setEditingRegularClassDef(regularClassDef || null)
        setOpenModal(true)
    }, [formik])
    const handleCloseModal = () => setOpenModal(false)

    return (
        <>
            <DataGrid
                sx={{
                    mt: 2,
                    backgroundColor: 'white'
                }}
                // loading={isLoading}
                rows={lessonList}
                columns={columns}

                // pagination
                // paginationMode='server'
                // paginationModel={paginationModel}
                // rowCount={data?.count || 0}
                // pageSizeOptions={PageSizeOptions}
                // onPaginationModelChange={setPaginationModel}

                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableRowSelectionOnClick
            />

            <Box>
                <Button sx={{ mt: 2 }}
                        variant="contained"
                        fullWidth={true}
                        onClick={() => handleOpenModal()}>
                    新增班次
                </Button>
            </Box>


            {/* Modal */}
            <ConfirmModal title={`${editingRegularClassDef && editingRegularClassDef._id ? '修改' : '新增'}班次`}
                          open={openModal}
                          onClose={handleCloseModal}
                          confirmButtonTitle={'儲存'}
                          confirmButtonProps={{
                              disabled: !formik.dirty || formik.isSubmitting
                          }}
                          onSubmit={formik.handleSubmit}>

                <TutorSelect
                    sx={{mt: 2}}
                    name="tutorId"
                    label="導師"
                    size="small"
                    fullWidth={true}
                    value={formik.values.tutorId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={!!formik.errors.tutorId && formik.touched.tutorId}
                    helperText={formik.errors.tutorId}/>

                <TextField sx={{mt: 2}}
                           name="venue.zh"
                           label="中文地址"
                           size="small"
                           fullWidth={true}
                           value={formik.values.venue.zh}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           error={!!formik.errors.venue && !!formik.errors.venue.zh && !!formik.touched.venue && formik.touched.venue.zh}
                           helperText={!!formik.errors.venue && formik.errors.venue.zh}
                />

                <TextField sx={{mt: 2}}
                           name="venue.en"
                           label="英文地址"
                           size="small"
                           fullWidth={true}
                           value={formik.values.venue.en}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           error={!!formik.errors.venue && !!formik.errors.venue.en && !!formik.touched.venue && formik.touched.venue.en}
                           helperText={!!formik.errors.venue && formik.errors.venue.en}
                />

                <TextField
                    sx={{mt: 2}}
                    name="weekday"
                    label="星期"
                    size="small"
                    fullWidth={true}
                    value={formik.values.weekday}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={!!formik.errors.weekday && formik.touched.weekday}
                    helperText={formik.errors.weekday}
                    select>
                    {
                        Array(7).fill(0).map((_, idx) => (
                            <MenuItem key={`weekday-${idx}`} value={idx}>
                                {`${dayjs().weekday(idx).toDate().toLocaleDateString('zh-HK', { weekday: 'long' })}`}
                            </MenuItem>
                        ))
                    }
                </TextField>

                <Box sx={{mt: 2, display: "flex", alignItems: "center"}}>
                    <DateTimeField label="開始時間"
                                   size="small"
                                   value={dayjs(formik.values.start)}
                                   format={"HH:mm"}
                                   onChange={(newValue) => formik.setFieldValue(`start`, newValue)}/>
                    <Typography component='div' sx={{ mx: 1 }}>-</Typography>
                    <DateTimeField label="完結時間"
                                   size="small"
                                   value={dayjs(formik.values.end)}
                                   format={"HH:mm"}
                                   onChange={(newValue) => formik.setFieldValue(`end`, newValue)}/>
                </Box>

                <TextField sx={{mt: 2}}
                           type="number"
                           name="quota"
                           label="名額"
                           size="small"
                           fullWidth={true}
                           value={formik.values.quota}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           error={!!formik.errors.quota && formik.touched.quota}
                           helperText={formik.errors.quota}
                />

                <TextField sx={{mt: 2}}
                           type="number"
                           name="notePages"
                           label="筆記頁數"
                           size="small"
                           fullWidth={true}
                           value={formik.values.notePages}
                           onChange={formik.handleChange}
                           onBlur={formik.handleBlur}
                           error={!!formik.errors.notePages && formik.touched.notePages}
                           helperText={formik.errors.notePages}
                />
            </ConfirmModal>

        </>
    )
}
