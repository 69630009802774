import {
    AdminCreateOrderPayload,
    UpdateOrderPayload,
    Order,
    OrderStatus,
    AdminCreateMultiOrderPayload, OrderFilter
} from "@models/order";
import {Pagination} from "common/models/pagination";
import axios from "axios";
import {SuccessRes} from "@/model/success-res";
import {CourseType} from "@models/course";

/***
 * @GET
 * /orders
 */

export type GetOrdersParams = OrderFilter

export interface GetOrdersRes {
    orders: Order[],
    count?: number
}

export async function getOrders(params: GetOrdersParams & Partial<Pagination>) {
    return await axios.get<GetOrdersRes>('/orders', {
        params,
        token: true
    })
}

/***
 * @GET
 * /orders/{userId}
 */

export interface GetOrderPathParams {
    id: string
}

export interface GetOrderRes {
    order: Order
}

export async function getOrder(pathParams: GetOrderPathParams) {
    return await axios.get<GetOrderRes>(`/orders/${pathParams.id}`, {
        token: true
    })
}

/***
 * @POST
 * /orders
 */

export type PostOrderBody = AdminCreateOrderPayload

export interface PostOrdersRes {
    order: Order
}

export async function postOrders(data: PostOrderBody) {
    return await axios.post<PostOrdersRes>('/orders', data, {token: true})
}

/***
 * @POST
 * /orders/multi
 */

export type PostOrdersBatchBody = AdminCreateMultiOrderPayload

export type PostOrdersBatchRes = SuccessRes

export async function postOrdersBatch(data: PostOrdersBatchBody) {
    return await axios.post<PostOrdersBatchRes>('/orders/multi', data, {token: true})
}

/***
 * @PATCH
 * /orders/{userId}
 */

export interface PatchOrderPathParams {
    id: string
}

export type PatchOrderData = UpdateOrderPayload

export async function patchOrder(pathParams: PatchOrderPathParams, data: PatchOrderData) {
    return await axios.patch<SuccessRes>(`/orders/${pathParams.id}`, data, {token: true})
}
