import { CourseType, WebCourseRes } from "@models/course";
import { DbDate } from "@models/common";

export interface Order {
    _id: string

    studentId: string

    courseId: number
    courseType: CourseType

    // regular
    classId?: number

    timeslots: OneOffOrderTimeslot[] | RegularOrderTimeslot[]

    price: number

    status: OrderStatus
    paymentMethod?: PaymentMethod

    stripeTransactionId?: string
    receiptRecords?: ReceiptRecord[]

    createdBy: string
    createdAt: DbDate
    paidAt?: DbDate
    refundedAt?: DbDate
}

export interface StudentCreateOrderPayload {
    courseId: number

    // regular
    classId?: number

    timeslots: OneOffOrderTimeslot[] | RegularOrderTimeslot[]
}

export type AdminCreateOrderPayload = Pick<Order,
    "courseId" | "studentId" | "timeslots" | "price" | "status" | // required
    "classId" | "paymentMethod" | "paidAt" | "refundedAt"> // optional

export type AdminCreateMultiOrderPayload = { orders: AdminCreateOrderPayload[] }

export type CheckoutPayload = Pick<Order,
    "courseId" | "timeslots" | // required
    "classId" | "paymentMethod"> // optional

export type UpdateOrderPayload = Partial<Pick<Order, "price" | "status" | "paymentMethod" | "paidAt" | "refundedAt">>

export interface ReceiptRecord {
    imageUrl: string
    rejectedReason?: string
}

export enum OrderStatus {
    Pending,
    OfflineSubmitted,
    OfflineRejected,
    Paid,
    Cancelled,
    Refunded
}

export const orderStatusList = [
    OrderStatus.Pending,
    OrderStatus.OfflineSubmitted,
    OrderStatus.OfflineRejected,
    OrderStatus.Paid,
    OrderStatus.Refunded,
]

export const orderStatusUI = {
    [OrderStatus.Pending]: {
        title: "待付款"
    },
    [OrderStatus.OfflineSubmitted]: {
        title: "已提交證明"
    },
    [OrderStatus.OfflineRejected]: {
        title: "已拒絕證明"
    },
    [OrderStatus.Paid]: {
        title: "已付款"
    },
    [OrderStatus.Refunded]: {
        title: "已退款"
    }
}

export enum PaymentMethod {
    CreditCard,
    Offline,
}

export interface OneOffOrderTimeslot {
    lessonId: number
    timeslotId: number
}

export interface RegularOrderTimeslot {
    month: string
    date: string
}

export interface OrderFilter {
    keyword?: string
    courseId?: number
    courseType?: CourseType
    studentId?: string
    status?: OrderStatus
}

export type WebOrderRes = WebCourseRes & {
    order: Order
}
