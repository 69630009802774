import React, {useContext, useEffect, useMemo, useState} from "react";
import {Snack, SnackbarContext} from "@/context/SnackbarContext";
import {
    Button,
    Checkbox, FormControl,
    FormControlLabel, FormGroup, FormHelperText, FormLabel,
    Grid,
    InputAdornment,
    ListItemText,
    MenuItem,
    Paper,
    TextField
} from "@mui/material";
import {chainRules, requiredInputStringRule} from "common/input-rules";
import {
    AdminCreateOrderPayload,
    OneOffOrderTimeslot,
    OrderStatus,
    orderStatusList, orderStatusUI,
    RegularOrderTimeslot, UpdateOrderPayload
} from "@models/order";
import { useFormik } from "formik";
import {CourseSelect} from "@/components/CourseSelect";
import {StudentSelect} from "@/components/StudentSelect";
import {getOneOffLessons, getRegularClasses} from "@/api/course";
import {
    Course,
    CourseType,
    OneOffLesson, REGULAR_CLASS_DATE_FORMAT,
    REGULAR_CLASS_MONTH_FORMAT,
    RegularClassDef,
    RegularClassInstance
} from "@models/course";
import dayjs from "dayjs";
import {getOrder, patchOrder, postOrders} from "@/api/order";
import {useParams} from "react-router-dom";
import {Level} from "@models/common";
import {constructInit} from "@/helpers/contructInit";

export default function OrderCreate() {

    const {snack, setSnack} = useContext(SnackbarContext);
    const routeParams = useParams();
    const orderId = useMemo<string | null>(() => {
        return routeParams && routeParams.id ? routeParams.id : null
    }, [routeParams]);

    const emptyOrderRequired = {
        courseId: -1,
        studentId: "",
        timeslots: [] as OneOffOrderTimeslot[] | RegularOrderTimeslot[],
        price: 0,
        status: OrderStatus.Pending,
    }

    const emptyOrderOptional = {
        classId: null,
        paymentMethod: null,
        paidAt: null,
        refundedAt: null
    }

    const [initialValues, setInitialValues] = useState({
        ...emptyOrderRequired
    });

    useEffect(() => {
        if (orderId) {
            getOrder({id: orderId}).then(res => {
                setInitialValues(constructInit(emptyOrderRequired, emptyOrderOptional, res.data.order))
                formik.resetForm()
            })
        }
    }, []);

    const formik = useFormik<AdminCreateOrderPayload>({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: async (values, {setFieldValue}) => {
            try {

                const body = {...values}
                if (course && course.type === CourseType.OneOff) {
                    body.timeslots = (formik.values.timeslots as OneOffOrderTimeslot[]).filter(t => t.timeslotId !== -1)
                }

                if (orderId) {
                    const updateBody: UpdateOrderPayload = {}
                    updateBody.price = body.price
                    updateBody.status = body.status
                    updateBody.paymentMethod = body.paymentMethod
                    updateBody.paidAt = body.paidAt
                    updateBody.refundedAt = body.refundedAt
                    await patchOrder({id: orderId}, updateBody)
                }
                else {
                    await postOrders(body)
                }
                setSnack(Snack.success('成功儲存'))
            }
            catch (e) {
                setSnack(Snack.error('儲存失敗'))
            }

        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: values => {
            let errors = {
                studentId: chainRules([requiredInputStringRule], values.studentId),
            }
            Object.trimLeaves(errors, [true, {}])
            return errors
        }
    });

    const [course, setCourse] = useState<Course | null>(null)
    const [oneOffLessons, setOneOffLessons] = useState<OneOffLesson[]>([])
    const [classes, setClasses] = useState<RegularClassInstance[]>([])
    const [classDefs, setClassDefs] = useState<RegularClassDef[]>([])

    useEffect(() => {
        if (!course) {
            formik.setFieldValue("price", 0)
            return
        }

        const perLessonPrice = course.discountedPricePerLesson || course.pricePerLesson || 0
        let lessonCount = 0
        if (course.type === CourseType.Regular) {
            lessonCount = formik.values.timeslots.length
        }
        else if (course.type === CourseType.OneOff) {
            lessonCount = (formik.values.timeslots as OneOffOrderTimeslot[]).filter(t => t.timeslotId !== -1).length
        }
        formik.setFieldValue("price", perLessonPrice * lessonCount)
    }, [formik.values.timeslots, course])

    const courseChanged = async (course?: Course) => {

        // Reset data options.
        setCourse(course || null)
        setOneOffLessons([])
        setClasses([])
        setClassDefs([])

        await formik.setFieldValue("timeslots", [])
        await formik.setFieldValue("classId", null)

        if (!course)
            return

        if (course.type === CourseType.Regular) {
            const thisMonth = dayjs().format("YYYY/MM")
            const nextMonth = dayjs().startOf("month").add(1, "month").format("YYYY/MM")
            const classesRes = await getRegularClasses({ courseId: course._id }, {
                fromMonth: thisMonth,
                toMonth: nextMonth,
            })
            const instances = classesRes.data.classes
            const lookup = instances.reduce((defLookup, instance) => ({
                ...defLookup,
                [instance.classId]: instance,
            }), {} as {[key:number]: RegularClassDef})

            await formik.setFieldValue("timeslots", [])
            await formik.setFieldValue("classId", null)
            setClasses(instances)
            setClassDefs(Object.values(lookup))
        }
        else if (course.type === CourseType.OneOff) {
            const oneOffLessonsRes = await getOneOffLessons({
                courseId: course._id
            }, {

            })
            const lessons = oneOffLessonsRes.data.lessons
            await formik.setFieldValue("timeslots", lessons.map(l => ({
                lessonId: l.lessonId,
                timeslotId: l.timeslots[0].id
            })))
            setOneOffLessons(lessons)
        }
    }

    // useEffect(() => {
    //     formik.setFieldValue("timeslots", classes.filter(c => c.classId === formik.values.classId).map(cls => cls.dates).flat().map(d => ({
    //         month: "",
    //         date: ""
    //     })))
    // }, [formik.values.classId]);

    const regularTimeslotChanged = (date: string) => {
        const timeslots = (formik.values.timeslots as RegularOrderTimeslot[])
        console.log(date)
        console.log(timeslots)
        if (!!timeslots.find(t => t.date === dayjs(date).format(REGULAR_CLASS_DATE_FORMAT) && t.month === dayjs(date).format(REGULAR_CLASS_MONTH_FORMAT))) {
            formik.setFieldValue("timeslots", timeslots.filter(t =>
                t.date !== dayjs(date).format(REGULAR_CLASS_DATE_FORMAT) || t.month !== dayjs(date).format(REGULAR_CLASS_MONTH_FORMAT)
            ))
        }
        else {
            formik.setFieldValue("timeslots", [
                ...timeslots,
                {
                    date: dayjs(date).format(REGULAR_CLASS_DATE_FORMAT),
                    month: dayjs(date).format(REGULAR_CLASS_MONTH_FORMAT)
                }
            ])
        }
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Button type="submit"
                        variant="contained"
                        disabled={!formik.dirty || formik.isSubmitting}>
                    儲存
                </Button>
                <Paper sx={{p: 4, mt: 2}}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <CourseSelect
                                name="courseId"
                                label="課程"
                                size="small"
                                fullWidth={true}
                                value={formik.values.courseId === -1 || !formik.values.courseId ? "" : formik.values.courseId}
                                onChange={formik.handleChange}
                                onCourseChanged={courseChanged}
                                onBlur={formik.handleBlur}
                                error={!!formik.errors.courseId && formik.touched.courseId}
                                helperText={formik.errors.courseId}
                                disabled={!!orderId}
                            ></CourseSelect>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                type="number"
                                name="status"
                                label="狀態"
                                size="small"
                                fullWidth={true}
                                value={formik.values.status}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={!!formik.errors.status && formik.touched.status}
                                helperText={formik.errors.status}
                                select>
                                {
                                    orderStatusList.map(s => (
                                        <MenuItem key={s} value={s}>
                                            {`${orderStatusUI[s].title}`}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <StudentSelect
                                size="small"
                                fullWidth={true}
                                value={formik.values.studentId || null}
                                onChange={(e, value) => formik.setFieldValue("studentId", value)}
                                onBlur={formik.handleBlur}
                                textFieldProps={{
                                    name: "studentId",
                                    label: "學生",
                                    error: !!formik.errors.studentId && formik.touched.studentId,
                                    helperText: formik.errors.studentId
                                }}
                                disabled={!!orderId}
                            ></StudentSelect>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type="number"
                                       name="price"
                                       label="價錢"
                                       size="small"
                                       fullWidth={true}
                                       value={formik.values.price}
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       error={!!formik.errors.price && formik.touched.price}
                                       helperText={formik.errors.price}
                                       InputProps={{
                                           startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                       }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            {
                                !!course && course.type === CourseType.OneOff && (
                                    oneOffLessons.map((l, idx) => (
                                        <TextField
                                            type="number"
                                            name={`timeslots.${idx}.timeslotId`}
                                            label={`第${idx + 1}堂`}
                                            size="small"
                                            fullWidth={true}
                                            value={(formik.values.timeslots as OneOffOrderTimeslot[])[idx].timeslotId}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={!!formik.errors.status && formik.touched.status}
                                            helperText={formik.errors.status}
                                            select>
                                            <MenuItem key={-1} value={-1}>
                                                不選擇
                                            </MenuItem>
                                            {
                                                l.timeslots.map(t => (
                                                    <MenuItem key={t.id} value={t.id}>
                                                        { `${dayjs(t.start).format('DD/MM/YYYY HH:mm')} - ${dayjs(t.end).format('DD/MM/YYYY HH:mm')}` }
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    ))
                                )
                            }


                            {/*{*/}
                            {/*    month: string*/}
                            {/*    date: string*/}
                            {/*}*/}

                            {
                                !!course && course.type === CourseType.Regular && (
                                    <>
                                        <TextField
                                            type="number"
                                            name="classId"
                                            label="班次"
                                            size="small"
                                            fullWidth={true}
                                            value={formik.values.classId || ''}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={!!formik.errors.status && formik.touched.status}
                                            helperText={formik.errors.status}
                                            select>
                                            {
                                                classDefs.map(classDef => (
                                                    <MenuItem key={classDef.classId} value={classDef.classId}>
                                                        { `${classDef.classId} - ${dayjs().weekday(classDef.weekday).toDate().toLocaleDateString('zh-HK', { weekday: 'long' })}` }
                                                        { `${dayjs(classDef.start).format('HH:mm')} - ${dayjs(classDef.end).format('HH:mm')}` }
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                        {
                                            !!formik.values.classId && (
                                                <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                                    <FormLabel component="legend">日期</FormLabel>
                                                    <FormGroup>
                                                        {
                                                            classes.filter(c => c.classId === formik.values.classId).map(cls => cls.dates).flat().map((d, idx) => (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox checked={!!(formik.values.timeslots as RegularOrderTimeslot[]).find(t => t.date === dayjs(d).format(REGULAR_CLASS_DATE_FORMAT) && t.month === dayjs(d).format(REGULAR_CLASS_MONTH_FORMAT) )}
                                                                                  onChange={() => regularTimeslotChanged(d)}
                                                                                  name={`timeslots.${idx}.date`} />
                                                                    }
                                                                    label={d}
                                                                />
                                                            ))
                                                        }
                                                    </FormGroup>
                                                    {/*<FormHelperText>Be careful</FormHelperText>*/}
                                                </FormControl>

                                                // <TextField
                                                //     type="number"
                                                //     name={`timeslots`}
                                                //     label={`課堂`}
                                                //     size="small"
                                                //     fullWidth={true}
                                                //     value={formik.values.timeslots}
                                                //     onChange={formik.handleChange}
                                                //     onBlur={formik.handleBlur}
                                                //     error={!!formik.errors.status && formik.touched.status}
                                                //     helperText={formik.errors.status}
                                                //     select
                                                //     SelectProps={{
                                                //         multiple: true,
                                                //     }}>
                                                //     {
                                                //         classes.filter(c => c.classId === formik.values.classId).map(cls => cls.dates).flat().map(date => (
                                                //             <MenuItem key={date} value={date}>
                                                //                 {/*<Checkbox checked={personName.indexOf(name) > -1} />*/}
                                                //                 <ListItemText primary={date} />
                                                //             </MenuItem>
                                                //         ))
                                                //     }
                                                // </TextField>
                                            )
                                        }
                                    </>
                                )
                            }
                        </Grid>

                    </Grid>
                </Paper>
            </form>
        </>
    )
}
