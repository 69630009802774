export function downloadFile(downloadUrl: string, fileName?: string) {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.target = "_blank";
    a.href = downloadUrl;
    a.download = fileName || downloadUrl.split("/").pop() || "file";
    a.click();
    window.URL.revokeObjectURL(downloadUrl);
    a.remove();
}
