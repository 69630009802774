import React, {useContext, useEffect, useMemo, useState} from "react";
import {SnackbarContext} from "@/context/SnackbarContext";
import {Box, Button, IconButton} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {PageSizeOptions} from "@/data/options";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import {useQuery} from "react-query";
import {QUERY_KEY} from "@/data/query-key";
import {GridRowSelectionModel} from "@mui/x-data-grid/models/gridRowSelectionModel";
import {getTutorLeaveRequests} from "@/api/tutor";
import {TutorLeaveRequest} from "@models/tutor";
import { useTutorStore } from "@/hooks/useTutorStore";
import {useAppDispatch} from "@/store";
import { getTutorLookup } from "@/store/slices/tutor";
import DoneIcon from "@mui/icons-material/Done";

export default function TutorLeaveRequestList() {

    const {snack, setSnack} = useContext(SnackbarContext);

    const columns: GridColDef<TutorLeaveRequest>[] = [
        {
            field: '_id',
            headerName: '請假申請ID',
            sortable: false,
            flex: 1,
            minWidth: 200
        },
        {
            field: 'tutorId',
            headerName: '導師名稱',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    <Box>
                        { tutorLookup[row.tutorId] ? tutorLookup[row.tutorId].name : "-" }
                    </Box>
                )
            }
        },
        {
            field: 'notiSent',
            headerName: '已通知',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    row.notiSent ? <DoneIcon /> : <></>
                )
            }
        },
        {
            field: 'createdAt',
            headerName: '創建日期',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => {
                return (
                    <Box>
                        { dayjs(row.createdAt).format('DD/MM/YYYY') }
                    </Box>
                )
            }
        },
        {
            field: 'action',
            headerName: '行動',
            sortable: false,
            minWidth: 120,
            renderCell: ({ row }) => {
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                        <IconButton
                            color='primary'
                            size='small'
                            sx={{ ml: 0.5 }}
                            component={RouterLink}
                            to={`/product-orders/edit/${row._id}`}
                        >
                            <EditIcon></EditIcon>
                        </IconButton>
                    </Box>
                )
            }
        }
    ]

    const [paginationModel, setPaginationModel] = useState<{page: number, pageSize: number}>({
        page: 0,
        pageSize: 50
    })

    const query = {
        skip: paginationModel.pageSize * paginationModel.page,
        limit: paginationModel.pageSize,
        count: true,
    }

    const { data, refetch, isLoading } = useQuery(
        [QUERY_KEY.TUTOR_LEAVE_REQUEST_LIST, query],
        async () => {
            return (await getTutorLeaveRequests(query)).data
        }
    )

    const tutorLeaveRequestList = useMemo<TutorLeaveRequest[]>(() => {
        if (!data)
            return []
        return data.tutors.map(r => ({
            id: r._id, // Add id for MUI
            ...r
        }))
    }, [data])

    /**
     * Init
     * */

    const dispatch = useAppDispatch()

    const {
        tutorLookup
    } = useTutorStore()

    useEffect(() => {
        dispatch(getTutorLookup())
    }, [])

    /***
     * Row actions
     */

    const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

    const handleRowSelectionChange = (rowSelectionModel: GridRowSelectionModel) => {
        setSelectedRows(rowSelectionModel);
    };

    return (
        <>
            <Button variant="contained" component={RouterLink} to="/product-orders/add">
                新增
            </Button>

            <DataGrid
                sx={{
                    mt: 2,
                    backgroundColor: 'white'
                }}
                loading={isLoading}
                rows={tutorLeaveRequestList}
                columns={columns}

                pagination
                paginationMode='server'
                paginationModel={paginationModel}
                rowCount={data?.count || 0}
                pageSizeOptions={PageSizeOptions}
                onPaginationModelChange={setPaginationModel}

                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableRowSelectionOnClick

                checkboxSelection
                onRowSelectionModelChange={handleRowSelectionChange}
            />
        </>
    )
}