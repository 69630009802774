import React, {useContext, useEffect, useMemo, useState} from "react";
import {Snack, SnackbarContext} from "@/context/SnackbarContext";
import {Box, Button, Grid, IconButton, InputAdornment, MenuItem, Paper, TextField} from "@mui/material";
import {chainRules, requiredInputStringRule} from "common/input-rules";
import {
    AdminCreateProductOrderPayload, CreateProductOrderItem, MailingStatus, mailingStatusList, mailingStatusUI,
} from "@models/product-order";
import { useFormik } from "formik";
import {StudentSelect} from "@/components/StudentSelect";
import {getProductOrder, patchProductOrder, postProductOrders} from "@/api/product-order";
import {useParams} from "react-router-dom";
import {constructInit} from "@/helpers/contructInit";
import {OrderStatus, orderStatusList, orderStatusUI} from "@models/order";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import { ProductSelect } from "@/components/ProductSelect";
import DeleteIcon from "@mui/icons-material/Delete";

export default function ProductOrderCreate() {

    const {snack, setSnack} = useContext(SnackbarContext);
    const routeParams = useParams();
    const productOrderId = useMemo<string | null>(() => {
        return routeParams && routeParams.id ? routeParams.id : null
    }, [routeParams]);

    const emptyProductOrderRequired = {
        productList: [] as CreateProductOrderItem[],
        studentId: "",
        price: 0,
        status: OrderStatus.Pending,
        mailingStatus: MailingStatus.Pending
    }

    const emptyProductOrderOptional = {
        paymentMethod: null,
        paidAt: null,
        refundedAt: null
    }

    const [initialValues, setInitialValues] = useState({
        ...emptyProductOrderRequired
    });

    useEffect(() => {
        if (productOrderId) {
            getProductOrder({id: productOrderId}).then(res => {
                setInitialValues(constructInit(emptyProductOrderRequired, emptyProductOrderOptional, res.data.productOrder))
                formik.resetForm()
            })
        }
    }, []);

    const formik = useFormik<AdminCreateProductOrderPayload>({
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: async (values, {setFieldValue}) => {
            try {

                const body = {...values}
                if (productOrderId) {
                    await patchProductOrder({id: productOrderId}, body)
                }
                else {
                    await postProductOrders(body)
                }
                setSnack(Snack.success('成功儲存'))
            }
            catch (e) {
                setSnack(Snack.error('儲存失敗'))
            }

        },
        validateOnBlur: false,
        validateOnChange: false,
        validate: (values: AdminCreateProductOrderPayload) => {
            let errors = {
                studentId: chainRules([requiredInputStringRule], values.studentId),
            }
            Object.trimLeaves(errors, [true, {}])
            return errors
        }
    });

    const clickAddProduct = () => {
        formik.setFieldValue("productList", [
            ...formik.values.productList,
            {
                productId: 0,
                quantity: 1
            }
        ])
    }

    const productList = [

    ]

    const columns: GridColDef<CreateProductOrderItem>[] = [
        {
            field: 'productId',
            headerName: '產品',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row, id }) => {
                return (
                    <Box sx={{ width: '100%' }}>
                        <ProductSelect
                            size="small"
                            fullWidth={true}
                            value={formik.values.productList[id].productId || null}
                            onChange={(e, value) => formik.setFieldValue(`productList.${id}.productId`, value)}
                            onBlur={formik.handleBlur}
                            textFieldProps={{
                                name: `productList.${id}.productId`,
                                label: "產品",
                                error: !!formik.errors.productList && !!formik.errors.productList[id].productId && formik.touched.productList && formik.touched.productList[id].productId,
                                helperText: formik.errors.productList && formik.errors.productList[id].productId,
                            }}
                        ></ProductSelect>
                    </Box>
                )
            }
        },
        {
            field: 'quantity',
            headerName: '數量',
            sortable: false,
            flex: 1,
            minWidth: 200,
            renderCell: ({ row, id }) => {
                return (
                    <Box sx={{ width: '100%' }}>
                        <TextField type="number"
                                   name={`productList.${id}.quantity`}
                                   label="數量"
                                   size="small"
                                   fullWidth={true}
                                   value={formik.values.productList[id].quantity}
                                   onChange={formik.handleChange}
                                   onBlur={formik.handleBlur}
                                   error={!!formik.errors.productList && !!formik.errors.productList[id].quantity && formik.touched.productList &&  formik.touched.productList[id].quantity}
                                   helperText={formik.errors.productList && formik.errors.productList[id].quantity}
                        />
                    </Box>
                )
            }
        },
        {
            field: 'action',
            headerName: '行動',
            sortable: false,
            minWidth: 120,
            renderCell: ({ row }) => {
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                        <IconButton
                            color='error' size='small'
                            onClick={() => clickDelete(row)}
                        >
                            <DeleteIcon></DeleteIcon>
                        </IconButton>
                    </Box>
                )
            }
        }
    ]

    const clickDelete = (item: CreateProductOrderItem) => {
        formik.setFieldValue("productList", [
            ...formik.values.productList.filter(p => p.productId !== item.productId),
        ])
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Button type="submit"
                        variant="contained"
                        disabled={!formik.dirty || formik.isSubmitting}>
                    儲存
                </Button>
                <Paper sx={{p: 4, mt: 2}}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField
                                type="number"
                                name="status"
                                label="狀態"
                                size="small"
                                fullWidth={true}
                                value={formik.values.status}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={!!formik.errors.status && formik.touched.status}
                                helperText={formik.errors.status}
                                select>
                                {
                                    orderStatusList.map(s => (
                                        <MenuItem key={s} value={s}>
                                            {`${orderStatusUI[s].title}`}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                type="number"
                                name="mailingStatus"
                                label="郵寄狀態"
                                size="small"
                                fullWidth={true}
                                value={formik.values.mailingStatus}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={!!formik.errors.mailingStatus && formik.touched.mailingStatus}
                                helperText={formik.errors.mailingStatus}
                                select>
                                {
                                    mailingStatusList.map(s => (
                                        <MenuItem key={s} value={s}>
                                            {`${mailingStatusUI[s].title}`}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <StudentSelect
                                size="small"
                                fullWidth={true}
                                value={formik.values.studentId || null}
                                onChange={(e, value) => formik.setFieldValue("studentId", value)}
                                onBlur={formik.handleBlur}
                                textFieldProps={{
                                    name: "studentId",
                                    label: "學生",
                                    error: !!formik.errors.studentId && formik.touched.studentId,
                                    helperText: formik.errors.studentId
                                }}
                                disabled={!!productOrderId}
                            ></StudentSelect>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type="number"
                                       name="price"
                                       label="價錢"
                                       size="small"
                                       fullWidth={true}
                                       value={formik.values.price}
                                       onChange={formik.handleChange}
                                       onBlur={formik.handleBlur}
                                       error={!!formik.errors.price && formik.touched.price}
                                       helperText={formik.errors.price}
                                       InputProps={{
                                           startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                       }}
                            />
                        </Grid>

                    </Grid>
                </Paper>
                <Paper sx={{p: 4, mt: 2}}>
                    <DataGrid
                        sx={{
                            mt: 2,
                            backgroundColor: 'white'
                        }}
                        // loading={isLoading}
                        rows={formik.values.productList.map((p, idx) => ({...p, id: idx}))}
                        columns={columns}

                        hideFooterPagination={true}

                        disableColumnFilter
                        disableColumnMenu
                        disableColumnSelector
                        disableRowSelectionOnClick
                    />

                    <Box>
                        <Button sx={{ mt: 2 }}
                                variant="contained"
                                fullWidth={true}
                                onClick={clickAddProduct}>
                            新增產品
                        </Button>
                    </Box>

                </Paper>
            </form>


        </>
    )
}
