import {Pagination} from "common/models/pagination";
import axios from "axios";
import {SuccessRes} from "@/model/success-res";
import {CourseType} from "@models/course";
import {AdminCreateProductOrderPayload, ProductOrder, UpdateProductOrderPayload} from "@models/product-order";
import {OrderStatus} from "@models/order";

/***
 * @GET
 * /product-orders
 */

export interface GetProductOrdersParams {
    keyword?: string,
    courseId?: number,
    courseType?: CourseType,
    studentId?: string,
    status?: OrderStatus
}

export interface GetProductOrdersRes {
    productOrders: ProductOrder[],
    count?: number
}

export async function getProductOrders(params: GetProductOrdersParams & Partial<Pagination>) {
    return await axios.get<GetProductOrdersRes>('/product-orders', {
        params,
        token: true
    })
}

/***
 * @GET
 * /product-orders/{productOrderId}
 */

export interface GetProductOrderPathParams {
    id: string
}

export interface GetProductOrderRes {
    productOrder: ProductOrder
}

export async function getProductOrder(pathParams: GetProductOrderPathParams) {
    return await axios.get<GetProductOrderRes>(`/product-orders/${pathParams.id}`, {
        token: true
    })
}

/***
 * @POST
 * /product-orders
 */

export type PostProductOrderBody = AdminCreateProductOrderPayload

export interface PostProductOrdersRes {
    productOrder: ProductOrder
}

export async function postProductOrders(data: PostProductOrderBody) {
    return await axios.post<PostProductOrdersRes>('/product-orders', data, {token: true})
}

/***
 * @PATCH
 * /product-orders/{productOrderId}
 */

export interface PatchProductOrderPathParams {
    id: string
}

export type PatchProductOrderData = UpdateProductOrderPayload

export async function patchProductOrder(pathParams: PatchProductOrderPathParams, data: PatchProductOrderData) {
    return await axios.patch<SuccessRes>(`/product-orders/${pathParams.id}`, data, {token: true})
}
